/**
 * Main tab that guides the user in the creation process of a pollution alert.
 * Will display successive questions about the situation, ask for photos, comments, etc,
 * and send the resulting alert to the server's database.
 */
import './TabAlerts.css'
import React, { useEffect, useRef, useState } from 'react'
import { IonAlert, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonList, IonLoading, IonModal, IonTitle, IonToolbar } from '@ionic/react'
import { GeoInput } from '../components/GeoCard'
import { YesNoMsgButton } from '../components/YesNoMsgButton'
import { PictureCard } from '../components/PictureCard'
import { arrowBack, checkmark, close, refresh } from 'ionicons/icons'
import { Storage } from '@ionic/storage'
import { AlertData, type AlertDescriptionNode, type ContactInfoProps} from '../components/CommonTypes'
import { allTrue, getDescriptionNodes } from '../components/CommonFunctions'
import { InputWithCheck } from '../components/InputWithCheck'
import { YesNoCard } from '../components/YesNoCard'
import config from '../data/config.json'
import axios from 'axios'
import { OpenPage } from '../components/Authentication'


/* Key-value storage to store all the alerts ever sent by the user, so that
 * the user can view it later. Also store the latest description tree of an alert
 * downloaded from the  server. */
const store = new Storage()
await store.create()


interface DescriptionProps {
  setResult: (res: any[]) => void
}

/** 
 * Returns an interactive card that guides the user in the description process
 * by presenting a series of choices, organized as a decision tree.
 * There are 3 levels, and each node of the sublevels can be selected only if a parent node was chosen
 *
 * @param {Function} setResult Function called to set the description back into memory, at the end of the process
 */
const DescriptionTreeCard: React.FC<DescriptionProps> = ({ setResult }) => {

  const [currentDescription, setCurrentDescription] = useState<AlertDescriptionNode[]>([])
  const [refNodesList, setRefNodesList] = useState<AlertDescriptionNode[]>([])

  /**
   * Get the list of description nodes from the server
   */
  useEffect(() => {
    async function getNodes(): Promise<void> {
      const descriptionNodes = await getDescriptionNodes()
      setRefNodesList(descriptionNodes)
    }
    getNodes()
      .catch((e) => { console.error(e) })
  }, [])

  /**
   * Get all the children (description nodes accessible from that node).
   * @param id Unique id of the node.
   * @returns The list of children nodes, may be empty.
   */
  function getChildren(id: number): AlertDescriptionNode[] {
    return refNodesList.filter(node => node.parents.includes(id)) // If the ID is listed as parents, then it's a chid
  }

  /**
   * Add the given node to the current description.
   * @param newNode New node to add.
   */
  function addNode(newNode: AlertDescriptionNode): void {
    const newDescription = [...currentDescription, newNode]
    const nextNodes = getChildren(newNode.id)
    if (nextNodes.length > 0) {
      setCurrentDescription(newDescription)
    } 
    else {
      // No more choices, end of the description process
      setResult(newDescription)
    }
  }

  // Waiting for the latest nodes list
  if (refNodesList === null) {
    return (
      <IonItem>
        <p>Chargement...</p>
      </IonItem>
    )
  }

  let options: AlertDescriptionNode[] = []
  if (currentDescription.length === 0) {
    options = refNodesList.filter(node => node.level === 'L1')  // Beginning of the description process
  } 
  else {
    const lastNode = currentDescription.slice(-1)[0]
    options = (lastNode != null) ? getChildren(lastNode.id) : []
  }

  if (options.length === 0) {
    return (
      <div>
        <h4>Chargement...</h4>
      </div>
    )
  }
  
  return (
    <div>
      {options.map(node => {
        return (
          <IonItem onClick={() => { addNode(node) }} key={node.id}>
            <h4>{node.name}</h4>
          </IonItem>
        )
      })}
    </div>
  )
}


interface ContactProps {
  /** Function that is called to save the answer in the memory. */
  setResult: (c: ContactInfoProps) => void
  /** Prefilled data */
  data?: undefined | ContactInfoProps
}

/** Display a contact form */
const ContactCard: React.FC<ContactProps> = ({ setResult, data }) => {
  const [usrName, setName] = useState<string>(data?.name ??  '')
  const [usrMail, setMail] = useState<string>(data?.mail ??  '')
  const [usrTel, setTel] = useState<string>(data?.tel ??  '')
  const [confirmBtnTxt, setConfirmBtnTxt] = useState<string>(data != null ? 'Suivant' : 'Passer')
  const [validatedFields, setValidatedFields] = useState<Record<string, boolean>>({'email': true, 'tel': true});

  function onNameChanged(name: string): void {
    setName(name)
    setConfirmBtnTxt('Suivant')
  }

  function onMailChanged(mail: string): void {
    setMail(mail)
    setConfirmBtnTxt('Suivant')
  }

  function onTelChanged(tel: string): void {
    setTel(tel)
    setConfirmBtnTxt('Suivant')
  }

  function confirm(): void {
    if(!allTrue(validatedFields)) {
      return 
    }
    const usrInfo: ContactInfoProps = ({ name: usrName, mail: usrMail, tel: usrTel })
    setResult(usrInfo)
  }

  function setValidated(fieldName: string, valid: boolean): void {
    validatedFields[fieldName] = valid
    setValidatedFields(validatedFields)
  }
 
  return (
    <div>
      <center>
        <h4>Voulez-vous renseigner un moyen de vous contacter ? (optionnel)</h4>
      </center>
      <IonInput labelPlacement='floating' label='Nom' value={usrName} type='text' onIonChange={ e => {if (e.target.value != null) {onNameChanged(e.target.value.toString()) }}} />
      <InputWithCheck format='email' labelPlacement='floating' label='Email' value={usrMail} type='email' 
        onValidation={(ok: boolean) => { setValidated('email', ok); }}
        onIonChange={ e => {if (e.target.value != null) {onMailChanged(e.target.value.toString() as string) }}}
      />
      <InputWithCheck format='tel' labelPlacement='floating' label='Téléphone' value={usrTel} type='tel' 
        onValidation={(ok: boolean) => { setValidated('tel', ok); }}
        onIonChange={ e => {if (e.target.value != null) {onTelChanged(e.target.value.toString() as string) }}} 
      />
      <IonButton onClick={confirm}>{confirmBtnTxt}</IonButton>
    </div>
  )
}

interface CommentProps {
  /** Function that is called when the user submit the form, to handle and save externally the comment */
  setComment: (comment: string) => void
  /** Prefilled comment */
  preComment?: undefined | string
}

/** 
 * Display a form to add a comment
 */
const CommentCard: React.FC<CommentProps> = ({ setComment, preComment }) => {
  const [confirmBtnTxt, setConfirmBtnTxt] = useState<string>(preComment != null ? 'Suivant' : 'Passer')
  const [usrComment, setUsrComment] = useState<string>(preComment ?? '')

  return (
    <div>
      <center>
        <h4>D'autres précisions, commentaires ?</h4>
      </center>
      <IonInput
        labelPlacement="floating"
        label=""
        value={usrComment}
        type="text"
        onIonChange={(e) => {
          setConfirmBtnTxt('Suivant')
          setUsrComment(e.detail.value ?? '')
        }}
      />
      <IonButton onClick={() => { setComment(usrComment); }}>{confirmBtnTxt}</IonButton>
    </div>
  )
}

/** 
 * Save a picture associated to the alert.
 * Do it separately to use the multipart/form-data
 *
 * @param picture   Picture file to upload
 * @param alertId   The unique ID of the alert to which associate the picture
 */
async function saveAlertPicture(picture: File, alertId: number): Promise<boolean> {
  const formData = new FormData()
  const url = config.url + '/api/alert_picture/'
  formData.append('picture', picture, picture.name)
  formData.append('alert_id', alertId.toString())
  try {
    console.debug("Uploading picture '" + picture.name + "' for alert '" + alertId.toString() + "'")
    await axios({
      url,
      data: formData,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return true
  } 
  catch {
    console.debug('Failed to upload picture')
    return false
  }
}

/** 
 * Save an alert both on the local storage and the remote database.
 * This is not valid for image and document_set,
 * which must be sent in multipart/form-data
 *
 * @param data        Alert to save
 * @param localIndex  The index of the alert in the local store, undefined if it has not been saved locally yet
 */
async function saveAlert(data: AlertData, localIndex: number | undefined = undefined): Promise<any> {
  const url = config.url + 'api/alert/'
  let myAlerts = (await store.get('my_alerts')) ?? []
  const normalData: any = { ...data }
  const pictures = data.alertpicture_set ?? []
  normalData.alertpicture_set = []
  normalData.pic_number = pictures.length
  let response: any

  try {
    response = await axios.post(url, normalData)
    data.saved = true
  } catch (error) {
    console.warn('Failed to save alert:')
    console.warn(error)
    data.saved = false
  }

  if (data.saved) {
    for (const pic of pictures) {
      const ok = await saveAlertPicture(pic, Number(response.data.data.id))
      data.saved = data.saved && ok
    }
  }

  if (localIndex === undefined) {
    myAlerts = [...myAlerts, data]
    await store.set('my_alerts', myAlerts)
  } else {
    myAlerts[localIndex] = data
    await store.set('my_alerts', myAlerts)
  }

  return { success: data.saved, alerts: myAlerts }
}


/**
 * Delete an Alert from local database.
 * @param localIndex  Index in the local database of the alert to delete.
 * @returns An dictionary representing the success and the new database.
 */
async function deleteAlert(localIndex: number): Promise<any> {
  const myAlerts = (await store.get('my_alerts')) ?? []
  const myNewAlerts = [...myAlerts]
  myNewAlerts.splice(localIndex, 1)
  await store.set('my_alerts', myNewAlerts)
  return { success: true, alerts: myNewAlerts }
}

interface AlertSaveProps {
  /** Alert object to send to the remote server */
  data: AlertData
  /** Callback functtion called at the end of the process (weither it succeeded of failed) */
  onEnd: () => void
}

const AlertSaveCard: React.FC<AlertSaveProps> = ({ data, onEnd }) => {
  const [saved, setSaved] = useState<boolean | undefined>(undefined)
  const [sending, setSending] = useState<boolean>(false)

  if (saved === undefined) {
    return (
      <>
        <IonButton
          disabled={sending}
          onClick={() => { (async () => { 
            setSending(true)  // Prevent alert to be sent multiple times
            document.getElementById('tab_alert_go_back_button')?.setAttribute('disabled', 'true')  // Prevent alert to be modified while sending
            const res = await saveAlert(data)  // IIFE design pattern
            setSending(false)
            setSaved(res.success === true)
          })().catch((e) => { console.error(e) })}}
        >
          Envoyer l'alerte
        </IonButton>
      </>
    )
  } else if (saved) {
    return (
      <IonAlert
        isOpen={true}
        message="Merci, votre alerte a bien été envoyée !"
        buttons={[
          {
            text: 'Ok',
            role: 'confirm',
          }
        ]}
        onDidDismiss={() => { onEnd(); }}
      />
    )
  } else {
    return (
      <IonAlert
        isOpen={true}
        header='Erreur'
        message="L'alerte n'a pas pu être envoyée ! Cela peut être dû à une mauvaise connexion internet. Une copie a été enregistrée localement et pourra être renvoyée ultérieurement."
        buttons={[
          {
            text: 'Ok',
            role: 'confirm',
          }
        ]}
        onDidDismiss={() => { onEnd(); }}
      />
    )
  }
}


interface NewAlertProps {
  /** Callback called when the alert creation modal is dismissed */
  onDismiss: () => void
}

/** 
 * Main element
 * Display content, based on known information.
 * The form that appears is the first one corresponding to a missing information (picture, location, description, etc...)
 */
const NewAlert: React.FC<NewAlertProps> = ({ onDismiss }) => {
  const [alertStep, setAlertStep] = useState(1)
  const [myalert, setMyalert] = useState<AlertData>(new AlertData())
  const modal = useRef<HTMLIonModalElement>(null)

  // Alert date is date of creation
  myalert.date = new Date() 

  function closeModal(): void {
    modal.current?.dismiss().catch((e) => { console.error(e) })
    setMyalert(new AlertData())
    onDismiss()
  }

  function setResult(name: string) {
    return (value: any) => {
      setMyalert((ale: any) => {
        ale[name] = value
        return ale
      })
      setAlertStep(alertStep + 1)
    }
  }

  let content

  if (alertStep === 1) {
    content = <PictureCard setResult={setResult('alertpicture_set')} />
  } else if (alertStep === 2) {
    content = <GeoInput setLocation={setResult('location')} icon={false} data={myalert.location} />
  } else if (alertStep === 3) {
    content = <DescriptionTreeCard setResult={setResult('description')} />
  } else if (alertStep === 4) {
    content = <YesNoCard text="Y a-t-il une mortalité de la faune et/ou de la flore ?" setResult={setResult('animal_mortality')} preSelection={myalert.animal_mortality} />
  } else if (alertStep === 5) {
    content = <YesNoCard text="Est-ce que cela affecte votre santé ou celle de votre entourage ?" setResult={setResult('health_symptoms')} preSelection={myalert.health_symptoms} />
  } else if (alertStep === 6) {
    content = <YesNoCard text="Y a-t-il une source visible de pollution ?" setResult={setResult('pollution_source')} preSelection={myalert.pollution_source} />
  } else if (alertStep === 7) {
    content = <ContactCard setResult={setResult('user')} data={myalert.user} />
  } else if (alertStep === 8) {
    content = <CommentCard setComment={setResult('comment')} preComment={myalert.comment} />
  } else if (alertStep === 9) {
    content = <AlertSaveCard data={myalert} onEnd={() => {setAlertStep(1); closeModal()}}/>
  } else {
    console.warn("Reached alert step " + alertStep + ", should never happen")
    setAlertStep(1)
    closeModal()
  }

  return (
    <>
      <IonButton id="new_alert_button">Nouvelle alerte</IonButton>
      <IonModal trigger="new_alert_button" ref={modal}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                id="tab_alert_go_back_button"
                onClick={() => {
                  const step = Math.max(0, alertStep - 1)
                  if (step < 1) {
                    closeModal()
                  } else {
                    setAlertStep(step)
                  }
                }}
                >
                <IonIcon icon={arrowBack}></IonIcon>
              </IonButton>
            </IonButtons>
            <IonTitle>Lancer une alerte</IonTitle>
            <IonButtons slot="end">
              <YesNoMsgButton
                icon={close}
                message="Voulez-vous retourner au menu d'accueil ?"
                onDidDismiss={(ok: boolean) => {
                  if (ok) {
                    setAlertStep(1)
                    closeModal()
                  }
                }}
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className='page_content'>
          {content}
        </IonContent>
      </IonModal>
    </>
  )
}


interface AlertCardProps {
  data: AlertData
  index: number
  setAlerts: (alert: any) => void
}


const AlertCard: React.FC<AlertCardProps> = ({ data, index, setAlerts }) => {
  const [saving, setSaving] = useState(false)
  data = new AlertData(data)  // Make it a full object to be able to access class methods

  return (
    <IonItem>
      <div className="alert_card_left">
        <p>{data.toShortStringDescription()}</p>
      </div>
      {saving ? (
        <IonLoading message="Envoi en cours..."></IonLoading>
      ) : (
        <div className="alert_card_right">
          {data.saved === true ? (
            <IonButton className="button_icon_ok">
              <IonIcon icon={checkmark}></IonIcon>
            </IonButton>
          ) : (
            <>
              <IonButton
                className="button_icon"
                onClick={() => {
                  setSaving(true)
                  saveAlert(data, index)
                    .then(result => { setAlerts(result.alerts) })
                    .catch(e => { console.error(e); })
                    .finally(() => { setSaving(false) })
                }}
              >
              <IonIcon className="" icon={refresh}></IonIcon>
              </IonButton>
              <IonButton
                className="button_icon_danger"
                onClick={() => {
                  setSaving(true)
                  deleteAlert(index)
                    .then(result => { setAlerts(result.alerts) })
                    .catch( e => { console.error(e) } )
                    .finally(() => { setSaving(false) })
                }}
              >
                <IonIcon className="" icon={close}></IonIcon>
              </IonButton>
            </>
          )}
        </div>
      )}
    </IonItem>
  )
}

/** 
 * Main component, home page.
 * Offer to send a new alert, or resend previous alerts
 */
const TabAlerts: React.FC = (): JSX.Element => {
  const [myAlerts, setMyalerts] = useState<AlertData[]>([])

  async function getAlerts(): Promise<void> {
    const alertsList: AlertData[] = (await store.get('my_alerts')) ?? []
    setMyalerts(alertsList)
  }

  function handleGetAlerts(): void {
    getAlerts()
      .then(() => { console.debug("Succesfully fetched alerts from local database") })
      .catch((e) => { console.error(e)} )
  }

  useEffect(() => {
    handleGetAlerts()
  }, [])

  const sent: Array<[AlertData, number]> = []
  const notSent: Array<[AlertData, number]> = []

  myAlerts.forEach((data, index) => {
    if (data.saved === true) {
      sent.push([data, index])
    } else {
      notSent.push([data, index])
    }
  })

  return (
    <OpenPage title={"Alertes"}>
      <NewAlert onDismiss={handleGetAlerts}/>
      {notSent.length > 0 ? (
        <>
          <h4>Alertes en attentes</h4>
          <IonList>
            {notSent.map((prop, num) => (
              <AlertCard data={prop[0]} index={prop[1]} key={`myalerts_${num}`} setAlerts={setMyalerts} />
            ))}
          </IonList>
        </>
      ) : (
        ''
      )}
      {sent.length > 0 ? (
        <>
          <h4>Alertes envoyées</h4>
          <IonList>
            {sent.map((prop, num) => (
              <AlertCard data={prop[0]} index={prop[1]} key={`myalerts_${num}`} setAlerts={setMyalerts} />
            ))}
          </IonList>
        </>
      ) : (
        ''
      )}
    </OpenPage>
  )
}

export default TabAlerts
