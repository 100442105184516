import React, { useState, useRef, useEffect } from 'react'
import { IonButton, IonButtons, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, useIonLoading, useIonModal } from '@ionic/react'
import { locate, map } from 'ionicons/icons'
import { Geolocation } from '@capacitor/geolocation'
import { type OverlayEventDetail } from '@ionic/core'
import { MapContainer, TileLayer } from 'react-leaflet'
import { Location } from './CommonTypes'
import 'leaflet/dist/leaflet.css'
import './GeoCard.css'
import { LatLngExpression, LatLngLiteral, LatLngTuple, Map, MapOptions, tileLayer } from 'leaflet'
import { lastIndexOf } from 'cypress/types/lodash'


interface GeoProps {
  /** Function to return the entered location data */
  setLocation: Function
  /** Wether to show buttons or icons to choose between the three modes (geolocation, enter manually, locate on map) */
  icon?: boolean
  /** Location data */
  data?: Location
}


/** Return the geolocation on button click */
const GpsLocator: React.FC<GeoProps> = ({ setLocation, icon = false }) => {
  const [present, dismiss] = useIonLoading()
  async function getloc() {
    present({
      message: 'Géolocalisation en cours...',
    })
    let coordinates
    try {
      coordinates = await Promise.race<{coords:{longitude:number, latitude:number}} | undefined>([
        Geolocation.getCurrentPosition(), 
        new Promise((res) => setTimeout(() => res(undefined), 20000))
      ]);
    } catch (error) {
      console.warn(error)
      dismiss()
      return false
    }
    dismiss()
    if (coordinates === undefined) {
      console.warn("Timeout")
    } else {
      setLocation({ longitude: coordinates?.coords.longitude, latitude: coordinates?.coords.latitude })
    }
  }
  if (icon) {
    return (
      <>
        <IonButton onClick={getloc} className="button_icon geo_locator_icon_button">
          <IonIcon icon={locate} />
        </IonButton>
      </>
    )
  }
  return <IonButton onClick={getloc}>Me géolocaliser</IonButton>
}


const MapLocator: React.FC<GeoProps> = ({ setLocation, icon = false, data }) => {
  const [loaded, setLoaded] = useState(false)
  const [present, dismiss] = useIonModal(MapLocatorModal, {
    data: data,
    dismiss: (data: undefined|{latitude: Number, longitude: Number}, 
              role: string) => { 
      if(data !== undefined){
        setLocation(new Location(data))
      }
      dismiss(data, role); },
    loaded: loaded,
  })

  function openModal(): void {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        setLoaded(false)
      },
      onDidPresent: (ev: CustomEvent<OverlayEventDetail>) => {
        setLoaded(true)
      }
    })
  }

  return (<>
      {icon ? (
        <IonButton
          className="button_icon geo_locator_icon_button" 
          onClick={() => { openModal(); }}
        >
          <IonIcon icon={map} />
        </IonButton>
      ) : (
        <IonButton onClick={() => { openModal(); }}>
          Localiser sur une carte
        </IonButton>
      )}
  </>)
}

interface MapLocatorModalProps{
  data: any,
  dismiss: Function,
  loaded: boolean,
}

/** Let the user locate the point on a map */
export const MapLocatorModal: React.FC<MapLocatorModalProps> = ({
    data, dismiss, loaded = false, 
  }) => {
  let startPos:LatLngLiteral = {lat: 48.58153047685794, lng: 7.750292410425198}
  if (data?.longitude && data?.latitude) {
    startPos = {lat: Number(data.latitude), lng: Number(data.longitude)}
  }
  const modal = useRef<HTMLIonModalElement>(null)
  const options: MapOptions = {
    center: startPos,
    zoom: 13,
  };
  const [myMap,setMyMap] = useState<null|L.Map>(null)

  useEffect(()=>{
    if(loaded && myMap===null){
      let myMap2 = new Map('geolocation_map', options)
      tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(myMap2);
      setMyMap(myMap2);
      console.debug("Load map")
    }
  },[loaded])
  
  return (
    <>
      <IonPage ref={modal}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={async () => {
                    dismiss(undefined);
                  }}>
                Retour
              </IonButton>
            </IonButtons>
            <IonTitle>Localiser</IonTitle>
            <IonButtons slot="end">
              <IonButton strong={true} onClick={async () => {
                    let lonlat = startPos
                    if (myMap!==null){
                      lonlat = myMap.getCenter()
                    }
                    dismiss({
                      latitude: lonlat.lat,
                      longitude: lonlat.lng,
                    });
                  }}>
                OK
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div id="map_container">
          {loaded?
            <div id="geolocation_map" ></div>
          :<></>}
          <IonIcon icon={locate} id="locate_icon"></IonIcon>
        </div>
      </IonPage>
    </>
  )
}

/** 
 * Display one button for each localisation method.
 */
export const GeoCard: React.FC<GeoProps> = ({ setLocation, icon = true, data }) => {
  return (
    <>
      <GpsLocator setLocation={setLocation} icon={icon} data={data} /> 
      <MapLocator setLocation={setLocation} icon={icon} data={data} />
    </>
  )
}

/** 
 * Graphical component that offers various options to enter a location (eg. from
 * a map, from GPS device...) and to display it and ask for confirmation.
 */
export const GeoInput: React.FC<GeoProps> = ({ setLocation, icon = true, data }) => {
  const [myloc, setMyloc] = useState<Location>(new Location(data))
  const [message, setMessage] = useState<string>(myloc.pprint())

  const setLocation2 = (loc: any) => {
    for (const [key, value] of Object.entries(myloc)) {
      if (loc[key] !== undefined) {
        myloc[key as keyof Omit<Location, "complete"|"pprint">] = loc[key]
      }
    }
    setMessage(myloc.pprint())
  }

  let validationDiv: JSX.Element = <span></span>
  if (message !== '') {
    validationDiv = (
      <div className="geo_validation_div">
        <div className="geo_loc_result">
          <h4>Localisation:</h4>
          <p>{message}</p>
        </div>
        <IonButton
          onClick={() => {
            setLocation(myloc)
          }}
        >
          Confirmer
        </IonButton>
      </div>
    )
  }

  return (
    <>
      <GeoCard setLocation={setLocation2} icon={icon} data={myloc} />
      {validationDiv}
    </>
  )
}