/**
 * Provide a component that displays a Yes/No popup and return the selected answer.
 */
import './YesNoCard.css'
import React from 'react'
import { IonButton } from '@ionic/react'


interface YesNoProps {
  /** Text of the question */
  text: string
  /** Function that is called to save the answer (true for 'yes', false for 'no') */
  setResult: (result: boolean) => void
  /** Preselected answer, to highlight the pre-selected choice (true for 'yes', false for 'no') */
  preSelection?: boolean | undefined
}


/** A simple card showing a Yes/No question */
export const YesNoCard: React.FC<YesNoProps> = ({ text, setResult, preSelection }) => {
  return (
    <div>
      <center>
        <h4>{text}</h4>
      </center>
      <IonButton onClick={() => { setResult(true) }} className={preSelection === false ? 'not_selected_button' : ''}>
        Oui
      </IonButton>
      <IonButton onClick={() => { setResult(false) }} className={preSelection === true ? 'not_selected_button' : ''}>
        Non
      </IonButton>
    </div>
  )
}