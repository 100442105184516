/**
 * Common functions shared accross several components of the app.
 */
import axios from 'axios'
import defaultAlertTree from '../data/default_alert_tree.json'
import config from '../data/config.json'
import { Storage } from '@ionic/storage'
import { AlertDescriptionNode } from './CommonTypes'
import { getPlatforms } from '@ionic/react'


/* Key-value storage to store all the alerts ever sent by the user, so that
 * the user can view it later. Also store the latest description tree of an alert
 * downloaded from the  server. */
const store = new Storage()
await store.create()


/**
 * Get the list of nodes available to describe an alert from the server.
 * If the downloading fails, fall back to alternative sources or previously
 * saved nodes tree list.
 * 
 * @returns The array of available nodes in the alert tree.
 */
export async function getDescriptionNodes(): Promise<AlertDescriptionNode[]> {
  const url = config.url + '/api/alert_tree/'
  let nodes: AlertDescriptionNode[]

  try {
    console.debug('Downloading latest alert nodes list from server...')
    const response = await axios.get(url)
    nodes = response.data.data.map((item: any) => new AlertDescriptionNode({"id":item.id,...item.attributes}))
    await store.set('description_nodes', nodes)
    console.debug('Done')
  }
  catch(error) {
    // Fallback to previously downloaded tree or default one
    console.debug('Fallback to previous tree')
    console.warn(error)
    nodes = await store.get('description_nodes')
    if (nodes === undefined) {
      console.debug('Fallback to hard-coded tree')
      nodes = defaultAlertTree.data.map((item: any) => new AlertDescriptionNode({"id":item.id,...item.attributes}))
      await store.set('description_nodes', nodes)
    }
  }

  return nodes
}


/** 
 * Check that a dictionary contains only true values.
 * 
 * @param dict  Dictionary (aka. Record) of string key and bolean values.
 * @returns True if all values in the dict are true, false otherwise.
 */
export function allTrue(dict: Record<string, boolean>): boolean {
  for (const key in dict) {
    if (!dict[key]) {
      return false
    }
  }
  return true
}


/** 
 * Transform a date to a string in the ISO format but in local time.
 * 
 * @param date  Date object to transform
 * @returns The ISO string representation.
 */
export function toIsoString(date: Date): string {
  const tzo = -date.getTimezoneOffset()
  const dif = tzo >= 0 ? '+' : '-'
  const pad = function (num: number): string {
    return (num < 10 ? '0' : '') + num
  }

  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + 'T' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds()) + dif + pad(Math.floor(Math.abs(tzo) / 60)) + ':' + pad(Math.abs(tzo) % 60)
}


/** 
 * Check if on a desktop browser
 */
export function isDesktop(): boolean {
  console.debug(getPlatforms())
  return !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test((getPlatforms().join(";"))))
}
