import React, { useState } from 'react'
import { IonButton, IonIcon, IonGrid, IonRow, IonCol, IonImg } from '@ionic/react'
import { camera } from 'ionicons/icons'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import './PictureCard.css'


interface PictureProps {
  /** Function to return and save the pictures loaded */
  setResult: (pictures: File[]) => void
}


/** 
 * Component that enables taking multiple pictures
 */
export const PictureCard: React.FC<PictureProps> = ({ setResult }) => {
  // States
  const [photos, setPhotos] = useState<File[]>([])                // Files to return
  const [webviewPaths, setWebviewPaths] = useState<string[]>([])  // Path to tmp memory for quick display
  const [btnTxt, setBtnTxt] = useState<string>('Passer')          // Text of button changes if at least one photo

  /**
   * Opens a user interface to take a photo with the camera or
   * to import it from its device's storage, and adds it
   * to the list of photos
   */
  async function addPhoto(): Promise<void> {
    const rawPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100
    })
    
    if (rawPhoto.webPath == null) {
      console.error("Could not get photo")
      return
    }

    // Get the raw bytes of the picture from memory
    const blob = await fetch(rawPhoto.webPath).then(async (r) => await r.blob())

    // Convert into a standard File object for caller
    const extension = '.' + blob.type .split('/').slice(-1)[0]  // Typically of format 'image/png'. Accessing 0th is safe from out-of-bound access.  
    const name = Date.now() + extension
    const currentTIme = new Date().getTime()
    const photo = new File([blob], name, {lastModified: currentTIme, type: blob.type})

    const newPhotos = [photo, ...photos]
    const newWebviewPaths = [rawPhoto.webPath, ...webviewPaths]
    setPhotos(newPhotos)
    setWebviewPaths(newWebviewPaths)
    setBtnTxt('Valider')
  }

  /**
   * Handle the click on button to add a photo.
   * Maintly a wrapper to handle potential errors.
   */
  function handleAddPhotoClick(): void {
    addPhoto()
      .then(() => {})
      .catch(e => {console.error(e)})
  }

  return (
    <div>
      <center>
        <h4>Voulez-vous fournir des photos ?</h4>
      </center>

      <IonButton onClick={handleAddPhotoClick}>
        <IonIcon icon={camera}></IonIcon>
      </IonButton>
      <IonButton onClick={() => { setResult(photos); }}>{btnTxt}</IonButton>

      <IonGrid>
        <IonRow>
          {photos.map((photo, index) => (
            <IonCol size="6" key={photo.name}>
              <IonImg src={webviewPaths[index]} />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </div>
  )
}
