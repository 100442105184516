import React from 'react'
import App from './App'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { createRoot } from 'react-dom/client'

void defineCustomElements(window) // Elements loader must be called before render

const container = document.getElementById('root')
if (container!==null){
  const root = createRoot(container)
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
}else{
  console.error("Root container cannot be null")
}
