import React, { useEffect, useState } from 'react'
import axios from 'axios'
import config from '../data/config.json'
import marker1 from '../images/marker1.svg'
import marker2 from '../images/marker2.svg'
import * as L from 'leaflet'
import { IonAlert, IonButton, IonButtons, IonContent, IonDatetime, IonDatetimeButton, IonHeader, IonIcon, IonInput, IonItem, IonList, IonMenu, IonMenuButton, IonMenuToggle, IonModal, IonPage, IonRange, IonSelect, IonSelectOption, IonTitle, IonToolbar, isPlatform, useIonModal } from '@ionic/react'
import { RequireLoginPage, getAuthToken } from '../components/Authentication'
import { AlertData, type Analysis, type Filter, Intervention, type Measurement, type CustomDocument } from '../components/CommonTypes'
import { Storage } from '@ionic/storage'
import { chevronDown, chevronUp, closeCircle, download, downloadOutline, filter, list, map } from 'ionicons/icons'
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet'
import { type OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces'
import { EditInterventionButton } from '../components/InterventionComponents'
import { allTrue, toIsoString } from '../components/CommonFunctions'
import { InputWithCheck } from '../components/InputWithCheck'

import 'leaflet/dist/leaflet.css'
import './TabData.css'


const store = new Storage()
await store.create()

interface AlertCardProps {
  alert: AlertData
  index: number
}

const AlertCard: React.FC<AlertCardProps> = ({ alert, index }) => {
  const [loaded, setLoaded] = useState(false)
  const [present, dismiss] = useIonModal(AlertModal, {
    alertId: alert.id,
    onDismiss: (data: any, role: string) => { dismiss(data, role); },
    loaded
  })

  function openModal(): void {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        setLoaded(false)
      },
      onDidPresent: (ev: CustomEvent<OverlayEventDetail>) => {
        setLoaded(true)
      }
    })
  }

  return (
    <IonItem className="tabdata-alert-card" onClick={() => { openModal(); }} key={`Alert_${index}`}>
      {alert.toShortHtmlDescription()}
    </IonItem>
  )
}

const mapIcon1 = new L.Icon({
  iconUrl: marker1,
  iconRetinaUrl: marker1,
  iconAnchor: [12, 44],
  popupAnchor: [0, -45],
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: [23, 45],
  className: 'leaflet_map_icon'
})

const mapIcon2 = new L.Icon({
  iconUrl: marker2,
  iconRetinaUrl: marker2,
  iconAnchor: [12, 44],
  popupAnchor: [0, -45],
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: [23, 45],
  className: 'leaflet_map_icon'
})

interface PointLocationProps {
  longitude: number
  latitude: number
  text?: string
  otherMarkers?: Array<{
    longitude: number | undefined
    latitude: number | undefined
    text?: string
  }>
}

const PointLocation: React.FC<PointLocationProps> = ({ longitude, latitude, text, otherMarkers }) => {
  if (longitude === undefined || latitude === undefined) {
    return <div>Position inconnue</div>
  }
  
  return (
    <>
      <div className="point_location_container">
        <MapContainer center={[latitude, longitude]} zoom={13} scrollWheelZoom={false}>
          <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={[latitude, longitude]} icon={mapIcon1}>
            {(text !== undefined) ? <Popup>{text}</Popup> : <></>}
          </Marker>
          {(otherMarkers !== undefined) ? (
            <>
              {otherMarkers.map((item, ind) => (
                <Marker position={[item.latitude??0, item.longitude??0]} icon={mapIcon2} key={`marker_${ind}`}>
                  {(item.text !== undefined) ? <Popup>{item.text}</Popup> : <></>}
                </Marker>
              ))}
            </>
          ) : (
            <></>
          )}
        </MapContainer>
      </div>
    </>
  )
}

interface AlertModalProps {
  /** ID of alert to display */
  alertId: string
  onDismiss: (data: any, role: string) => void
  loaded: boolean
}

/**
 * Modal component that displays the data about an Alert.
 */
const AlertModal = ({ alertId, onDismiss, loaded }: AlertModalProps): JSX.Element => {
  const [alert, setAlert] = useState<AlertData>(new AlertData(null))

  /**
   * Get all the data associated to the given alert from the server.
   */
  async function getAlertDetails(): Promise<void> {
    const token = await getAuthToken(true)
    const response = await axios({
      url: `${config.url}/api/alert_detail/${alertId}/`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token === null ? '' : token.access}`,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json'
      }
    })
    setAlert(new AlertData(response.data))
  }

  /**
   * Triggers asynchronous downloading of all alert data.
   */
  useEffect(() => {
    getAlertDetails()
      .then()
      .catch(e => {console.error("Failed to download alert details: ", e)})
  }, [])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Alerte</IonTitle>
          <IonButtons slot="end">
            <IonButton color="medium" onClick={() => {onDismiss(null, 'cancel')}}>
              <IonIcon icon={closeCircle} slot="end" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page_content">
        <div className="alert_modal_description">
          <b>
            {alert.description?.map((item, ind) => {
              return <p key={`description_${ind}`}>{item.name}</p>
            })}
          </b>
        </div>
        {alert.date !== undefined ? (<div className="alert_modal_date">Le <em>{alert.date.toLocaleString()}</em></div>) : (<></>)}
        <br />
        <div>
          <b>Commentaire</b>
          <p>{alert.comment !== "" ? alert.comment : '(aucun commentaire)'}</p>
        </div>
        {loaded && alert.location?.longitude !== undefined && alert.location?.latitude !== undefined ? <PointLocation longitude={alert.location?.longitude} latitude={alert.location?.latitude} /> : <></>}
      </IonContent>
    </IonPage>
  )
}

interface InterventionCardProps {
  intervention: Intervention
  index: number
}

const InterventionCard: React.FC<InterventionCardProps> = ({ intervention, index }) => {
  const [loaded, setLoaded] = useState(false)
  const [present, dismiss] = useIonModal(InterventionModal, {
    intervention,
    onDismiss: (data: any, role: string) => { dismiss(data, role); },
    loaded
  })

  function openModal(): void {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        setLoaded(false)
      },
      onDidPresent: (ev: CustomEvent<OverlayEventDetail>) => {
        setLoaded(true)
      }
    })
  }

  return (
    <IonItem className="tabdata-intervention-card" onClick={() => { openModal(); }} key={`Intervention_${index}`}>
      {intervention.shortDescription(true)}
    </IonItem>
  )
}

const AnalysisCard: React.FC<{analysis: Analysis, index: number}> = ({ analysis, index }) => {
  return (
    <div className="tabdata_analysis_card">
      <h5>Analyse #{index + 1}</h5>
      {((analysis.location.other!=="") && (analysis.location.other!==undefined))? (<p><em>"{analysis.location.other}"</em></p>) : (<></>)}
      {analysis.measurement_set.map((item, ind) => {return <MeasurementCard measurement={item} key={`measurement_${ind}`} />})}
    </div>
  )
}

const MeasurementCard: React.FC<{measurement: Measurement}> = ({ measurement }) => {
  return (
    <div>
      <p>
        <b>{measurement.get_name()} : </b>
        {measurement.value} {measurement.get_unit()}
        <br />
        {measurement.date != null ? <span className="em_light">(Le {measurement.date.toLocaleString()})</span> : <></>}
      </p>
    </div>
  )
}

const FileCard: React.FC<{ doc: CustomDocument }> = ({ doc }) => {
  return (
    <div className="tabdata_file_card">
      {doc.webpath != null ?
        <a href={`${config.url}${doc.webpath}`} className="tabdata_file_card" target="_blank" rel="noreferrer">
          {doc.name}
          <IonIcon icon={downloadOutline} />
        </a>  
      :
        <div className="tabdata_file_card">
          {doc.name}
        </div>  
      }
    </div>
  )
}

interface InterventionModalProps {
  intervention: Intervention
  onDismiss:  (data: any, role: string) => void
  loaded: boolean
}

const InterventionModal = ({ intervention, onDismiss, loaded }: InterventionModalProps): JSX.Element => {
  const [myIntervention, setMyIntervention] = useState<Intervention>(intervention)

  async function getInterventionDetails(): Promise<void> {
    const token = await getAuthToken(true)
    const response = await axios({
      url: `${config.url}/api/intervention_detail/${myIntervention.id}/`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token === null ? '' : token.access}`,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json'
      }
    })
    setMyIntervention(new Intervention(response.data))
  }

  useEffect(() => {
    getInterventionDetails()
      .then()
      .catch(e => {console.error("Failed to download intervention details: ", e)})
  }, [])

  const otherMarkers: any[] = []
  if (myIntervention.analysis_set.length > 0) {
    myIntervention.analysis_set.forEach((item, ind) => {
      if (item.location.complete()) {
        otherMarkers.push({
          latitude: item.location.latitude,
          longitude: item.location.longitude,
          text: `Analyse #${ind + 1}`
        })
      }
    })
  }

  function setParentIntervention(inter:Intervention):void{
    console.log(inter)
    const inter2:Intervention=new Intervention(inter)
    setMyIntervention(inter2)
    console.log(inter2)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Intervention</IonTitle>
          <IonButtons slot="end">
            {(myIntervention.permissions?.edit != null) && (myIntervention.permissions?.edit) ?
              <EditInterventionButton intervention0={myIntervention} icon={true} setParentIntervention={setParentIntervention}/>
              :<></>}
            <IonButton color="medium" onClick={() => { onDismiss(null, 'cancel') }}>
              <IonIcon icon={closeCircle} slot="end" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page_content">
        <h2>{myIntervention.name}</h2>
        <div className="intervention_modal_description">
          {myIntervention.date != null ? (
            <div className="intervention_modal_date em_light">
              Le <em>{myIntervention.date.toLocaleString()}</em>
            </div>
          ) : (
            <></>
          )}
          {myIntervention.username.length > 0 ? <p>Référent: {myIntervention.username}</p> : <></>}
          {myIntervention.comment.length > 0 ? (<p><em>"{myIntervention.comment}"</em></p>) : (<></>)}
        </div>
        {myIntervention.analysis_set.length > 0 ? 
        <div className="intervention_modal_analyses">
          {myIntervention.analysis_set.map((item, ind) => (<AnalysisCard analysis={item} index={ind} key={`intervention_analysis_${ind}`} />))}
        </div> : <></>}
        <br />
        {loaded && (myIntervention.location.longitude != null) && (myIntervention.location.latitude != null) ? <PointLocation longitude={myIntervention.location.longitude} latitude={myIntervention.location.latitude} text="Intervention" otherMarkers={otherMarkers} /> : <></>}
        {myIntervention.document_set.length > 0 ? 
          (<div className="intervention_modal_files">
            {myIntervention.document_set.filter(
              (item) => {console.log(myIntervention);console.log(item);return !item.delete}).map(
              (item, ind) => <FileCard doc={item} key={`file_${ind}`} />)}
          </div>
        ) : (
          <></>
        )}
        <br />
      </IonContent>
    </IonPage>
  )
}

interface FilterPageProps {
  /** Specification of data to get */
  filters: Filter
  /** Function to fetch the data specified by the server. */
  updateFilters: (filters: Filter) => Promise<void> 
  /** Default filter */
  filters0: Filter
}


const FilterPage: React.FC<FilterPageProps> = ({ filters, updateFilters, filters0 }) => {
  const [validatedFields, setValidatedFields] = useState<Record<string, boolean>>({});
  const [invalidForm, setInvalidForm] = useState<boolean>(false)

  /** Validate fields */
  function setValidated(fieldName: string, valid: boolean): void {
    validatedFields[fieldName] = valid
    setValidatedFields(validatedFields)
  }

  /**
   * Handler for the update/fetching of data from server.
   * 
   * @param filters   Filters specification of the data to fetcth
   */
  function handleUdpateFilter(filters: Filter): void {
    updateFilters(filters)
      .then()
      .catch(e => {console.error("Failed to update filter: ", e)})
  }

  // Set argument from input
  function setArg(argName: keyof Filter) {
    return (e: any) => {
        filters[argName] = e.detail.value ?? ''
    }
  }

  // Arguments for radius
  const radiusValues = [0, 1, 5, 10, 20, 50, 100, 200]
  const radiusPinFormatter = (pin: number): string => {
    pin = Math.min(radiusValues.length - 1, Math.max(0, parseInt(String(pin))))
    return `${radiusValues[pin]}`
  }
  const setRadius = (e: any): void => {
    const val = Math.min(radiusValues.length - 1, Math.max(0, parseInt(String(e.detail.value))))
    filters.radius = radiusValues[val]
  }
  let radiusIndex: number = radiusValues.indexOf(parseInt(String(filters.radius)))
  if (radiusIndex === -1) {
    radiusIndex = 0
  }

  return (
    <>
      <IonSelect label="Type" value={['0', '1', '2'].includes(String(filters.type)) ? filters.type : '0'} onIonChange={setArg('type')}>
        <IonSelectOption key="0" value="0">
          Alertes et interventions
        </IonSelectOption>
        <IonSelectOption key="1" value="1">
          Alertes
        </IonSelectOption>
        <IonSelectOption key="2" value="2">
          Interventions
        </IonSelectOption>
      </IonSelect>

      <div id="tabdata_date_div">
        <p>Du</p>
        <IonDatetimeButton datetime={'filter_from_date'}></IonDatetimeButton>
        <IonModal keepContentsMounted={true}>
          <IonDatetime presentation="date" id={'filter_from_date'} onIonChange={(e) => (filters.from_date = new Date(String(e.detail.value)))} value={toIsoString(filters.from_date ?? new Date())}></IonDatetime>
        </IonModal>
        <p>à</p>
        <IonDatetimeButton datetime={'filter_to_date'}></IonDatetimeButton>
        <IonModal keepContentsMounted={true}>
          <IonDatetime presentation="date" id={'filter_to_date'} onIonChange={(e) => (filters.to_date = new Date(String(e.detail.value)))} value={toIsoString(filters.to_date ?? new Date())}></IonDatetime>
        </IonModal>
      </div>

      <IonInput value={filters.keywords ?? ''} onIonChange={setArg('keywords')} label="Mots clés" />
      <h4>Coordonnées</h4>
      <div>
        <InputWithCheck regexValidator={/^[-+]?\d{0,2}([.]\d*)?$/}
          onValidation={(ok: boolean) => { setValidated('tel', ok); }} 
          value={filters.longitude != null ? filters.longitude.toString() : ''} 
          onIonChange={setArg('longitude')} 
          label="Longitude"
          labelPlacement="floating" 
        />
        <InputWithCheck regexValidator={/^[-+]?\d{0,2}([.]\d*)?$/}
          onValidation={(ok: boolean) => { setValidated('tel', ok); }} 
          value={filters.latitude != null ? filters.latitude.toString() : ''} 
          onIonChange={setArg('latitude')} 
          label="Latitude" 
          labelPlacement="floating" 
        />
      </div>
      <div>
        Dans un rayon de (km)
        <IonRange label="" snaps={true} min={0} max={radiusValues.length - 1} pin={true} pinFormatter={radiusPinFormatter} onIonChange={setRadius} value={radiusIndex}></IonRange>
      </div>
      <IonButton onClick={(e) => {
        if(!allTrue(validatedFields)){
          setInvalidForm(true)
          return 
        }
        handleUdpateFilter(filters)
        }}
      >
        Rechercher
      </IonButton>
      <IonAlert isOpen={invalidForm}
        header="Champs non valides"
        message="Veuillez vérifier les données rentrées."
        buttons={['Fermer']}
        onDidDismiss={() => { setInvalidForm(false); }}
      />
      <IonButton onClick={ () => {handleUdpateFilter(filters0)} } className="button_danger">
        Reset
      </IonButton>
    </>
  )
}

interface DataListProps {
  alerts: AlertData[]
  interventions: Intervention[]
}

const DataList: React.FC<DataListProps> = ({ alerts, interventions }) => {
  const [showAlerts, setShowAlerts] = useState<boolean>(false)
  const [showInterventions, setShowInterventions] = useState<boolean>(false)

  return (
    <>
      <IonButton onClick={() => { setShowAlerts((r) => !r); }}>
        Alertes
        <IonIcon icon={showAlerts ? chevronUp : chevronDown} slot="end" />
      </IonButton>
      {showAlerts ? (
        <IonList>
          {alerts.map((val, ind) => {
            return <AlertCard alert={val} index={ind} key={ind}></AlertCard>
          })}
        </IonList>
      ) : (
        <></>
      )}
      <IonButton onClick={() => { setShowInterventions((r) => !r); }}>
        Interventions
        <IonIcon icon={showInterventions ? chevronUp : chevronDown} slot="end" />
      </IonButton>
      {showInterventions ? (
        <IonList>
          {interventions.map((val, ind) => {
            return <InterventionCard intervention={val} index={ind} key={ind}></InterventionCard>
          })}
        </IonList>
      ) : (
        <></>
      )}
    </>
  )
}

interface DataMapProps {
  alerts: AlertData[]
  interventions: Intervention[]
  center: [number, number]
}

const DataMap: React.FC<DataMapProps> = ({ alerts, interventions, center }) => {
  const [showAlerts, setShowAlerts] = useState<AlertData[]>([])
  const [showInterventions, setShowInterventions] = useState<Intervention[]>([])

  /** On marker click, show the corresponding marker information */
  function setVisible(longitude: number | undefined, latitude: number | undefined): void {
    const tabAlerts: AlertData[] = []
    const tabInterventions: Intervention[] = []
    const lonlat: Array<undefined | number> = [longitude, latitude]
    if (lonlat[0] !== undefined) {
      alerts.forEach((val, ind) => {
        if (val.location?.longitude === lonlat[0] && val.location?.latitude === lonlat[1]) {
          tabAlerts.push(val)
        }
      })
      interventions.forEach((val, ind) => {
        if (val.location?.longitude === lonlat[0] && val.location?.latitude === lonlat[1]) {
          tabInterventions.push(val)
        }
      })
    }
    setShowAlerts(tabAlerts)
    setShowInterventions(tabInterventions)
  }

  const OnMapClose: React.FC = () => {
    useMapEvents({
      click() {
        setVisible(undefined, undefined)
      }
    })
    return <></>
  }

  return (
    <div id="tabdata_map_page">
      <div id="tabdata_map_container" style={{ height: (showAlerts.length + showInterventions.length > 0) && (!isPlatform("desktop")) ? '80%' : '100%' }}>
        <MapContainer center={center} zoom={9} scrollWheelZoom={false}>
          <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {alerts.map((myAlert, ind) => {
            if (myAlert.location === undefined || !myAlert.location?.complete()) {
              return <></>
            }
            const position: L.LatLngTuple = [myAlert.location.latitude??-1, myAlert.location.longitude??-1]
            return (
              <Marker
                position={position}
                key={`marker_analysis_${ind}`}
                icon={mapIcon1}
                eventHandlers={{
                  click: (e: any) => {
                    setVisible(myAlert.location?.longitude, myAlert.location?.latitude)
                  }
                }}
              >
              </Marker>
            )
          })}
          {interventions.map((intervention, ind) => {
            if (!intervention.location.complete()) {
              return <></>
            }
            const position: L.LatLngTuple = [intervention.location.latitude??-1, intervention.location.longitude??-1]
            return (
              <Marker
                position={position}
                key={`marker_intervention_${ind}`}
                icon={mapIcon2}
                eventHandlers={{
                  click: (e: any) => {
                    setVisible(intervention.location?.longitude, intervention.location?.latitude)
                  }
                }}
              >
              </Marker>
            )
          })}
          <OnMapClose />
        </MapContainer>
      </div>
      <div id="tabdata_map_list">
        {showAlerts.length > 0 ? (
          <>
            <h4>Alertes</h4>
            <IonList>
              {showAlerts.map((val, ind) => {
                return <AlertCard alert={val} index={ind} key={`alert_card_${ind}`}></AlertCard>
              })}
            </IonList>
          </>
        ) : (
          <></>
        )}
        {showInterventions.length > 0 ? (
          <>
            <h4>Interventions</h4>
            <IonList>
              {showInterventions.map((val, ind) => {
                return <InterventionCard intervention={val} index={ind} key={`intervention_card_${ind}`}></InterventionCard>
              })}
            </IonList>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

const TabData: React.FC = () => {
  const [alerts, setAlerts] = useState<AlertData[]>([])
  const [interventions, setInterventions] = useState<Intervention[]>([])
  const displayChoices = ['Carte', 'Liste', 'Filtres', 'Téléchargement']
  const [display, setDisplay] = useState<number>(0)
  const myDate: Date = new Date()
  myDate.setMonth(myDate.getMonth() - 12)
  const mapCenter0: [number, number] = [48.58153047685794, 7.750292410425198]
  const mapCenter = mapCenter0     // Was initially a state init with an hard-coded value (forgotten debug code ? TODO: investigate)
  const filters0 = {
    type: '0',
    from_date: myDate,
    to_date: new Date(),
    keywords: undefined,
    country: undefined,
    city: undefined,
    longitude: mapCenter[1],
    latitude: mapCenter[0],
    radius: 0,
    max_num: undefined,
    excel: false
  }
  const [filters, setFilters] = useState<Filter>({ ...filters0 })
  const [downloadError, setDownloadError] = useState<boolean>(false)

  async function getData(): Promise<false | undefined> {
    const token = await getAuthToken(true)
    filters.excel = false
    let response
    let response2

    try {
      response = await axios({
        url: `${config.url}/api/data_list/`,
        params: filters,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token === null ? '' : token.access}`,
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json'
        }
      })
    } catch (e) {
      console.error('Failed to get data: ', e)
      setAlerts([])
      setInterventions([])
      return false
    }

    try {
      response2 = await axios({
        url: `${config.url}/api/alert_tree/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json'
        }
      })
    } catch (e) {
      console.log(e)
      return false
    }

    const alertTree : any = {} 
    response2.data.data.forEach((node:any)=>{
      alertTree[node.id]= {
        id: node.id,
        name: node.attributes.name,
        level: node.attributes.level,
        parents: node.attributes.parents,
      }
    })

    const alerts : AlertData[] = response.data.alerts.filter(
      (item: AlertData | undefined) => item !== undefined).map(
      (item: AlertData) => {
        if (item.description!==undefined){
          item.description = item.description.map((node:any)=>{
            try{
              return alertTree[node.id]
            }catch(e){
              return undefined
            }
          })
        }
        return new AlertData(item)
      })

    const interventions : Intervention[] = response.data.interventions.filter(
      (item: any) => item !== undefined).map(
      (item: any) => new Intervention(item))
    setAlerts(alerts)
    setInterventions(interventions)
  }

  async function getExcel(): Promise<void> {
    const token = await getAuthToken(true)
    filters.excel = true
    let response
    try {
      response = await axios({
        url: `${config.url}/api/data_list/`,
        params: filters,
        method: 'GET',
        headers: { Authorization: `Bearer ${token === null ? '' : token.access}` },
        responseType: 'blob'
      })
    } catch (e) {
      console.log(e)
      setAlerts([])
      setInterventions([])
      console.error("Failed to download as Excel file", e)
      setDownloadError(true)
      return
    }

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `data_${Date.now()}.xlsx`)
    document.body.appendChild(link)
    link.click()
  }

  useEffect(() => {
    getData()
      .then()
      .catch(e => {console.error('Failed to get data: ', e)})
  }, [])

  async function updateFilters(filters: Filter): Promise<void> {
    setFilters(filters)
    await getData()
    setDisplay(0)
  }

  let content = <></>

  if (display === 0) {
    content = <DataMap alerts={alerts} interventions={interventions} center={mapCenter} />
  } else if (display === 1) {
    content = <DataList alerts={alerts} interventions={interventions} />
  } else if (display === 2) {
    content = <FilterPage filters={filters} updateFilters={updateFilters} filters0={filters0} />
  } else if (display === 3) {
    content = (<>
      <IonButton onClick={ () => { getExcel().catch( e => {console.error("Failed to download as Excel file", e)} ); }}>
        <IonIcon icon={downloadOutline} />
      </IonButton>
      <IonAlert isOpen={downloadError}
        header="Erreur de téléchargement"
        message="Le téléchargement n'a pas abouti."
        buttons={['Fermer']}
        onDidDismiss={() => { setDownloadError(false); }}
      />
    </>)
  }

  return (
    <>
      <IonMenu contentId="data_main_content">
        <IonHeader>
          <IonToolbar>
            <IonTitle>Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonMenuToggle>
            <IonButton onClick={() => { setDisplay(0); }} className="data_menu_button">
              <div>
                <IonIcon icon={map}></IonIcon>
                <p>{displayChoices[0]}</p>
              </div>
            </IonButton>
            <IonButton onClick={() => { setDisplay(1); }} className="data_menu_button">
              <div>
                <IonIcon icon={list}></IonIcon>
                <p>{displayChoices[1]}</p>
              </div>
            </IonButton>
            <IonButton onClick={() => { setDisplay(2); }} className="data_menu_button">
              <div>
                <IonIcon icon={filter}></IonIcon>
                <p>{displayChoices[2]}</p>
              </div>
            </IonButton>
            <IonButton onClick={() => { setDisplay(3); }} className="data_menu_button">
              <div>
                <IonIcon icon={download}></IonIcon>
                <p>{displayChoices[3]}</p>
              </div>
            </IonButton>
          </IonMenuToggle>
        </IonContent>
      </IonMenu>
      <RequireLoginPage
        title="Données"
        headerButtons={<IonMenuButton></IonMenuButton>}
        authMessage="Veuillez vous connecter pour accéder à cette page"
        id="data_main_content"
        onAuthentication={(connected: boolean) => {
          if (connected) {
            getData()
            .then()
            .catch(e => {console.error('Failed to get data: ', e)})
          } else {
            setAlerts([])
            setInterventions([])
          }
        }}
      >
        {content}
      </RequireLoginPage>
    </>
  )
}

export default TabData
