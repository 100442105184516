import React, { useId }  from 'react'
import { IonAlert, IonButton, IonIcon } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'
import './YesNoMsgButton.css'


interface YesNoMsgButtonProps {
  /** Message to display */
  message: string
  /** Callback function called when the popup is dismissed. Argument passed is true if Yes clicked, false if No clicked. */
  onDidDismiss: (result: boolean) => void
  /** Icon of the button. Default icon is used if left unspecified */
  icon?: any
  /** Class to give to the button, for eg. CSS styling */
  className?: string
}


/**
 * Provide a button that, when clicked, diplays a Yes/No popup dialog box.
 */
export const YesNoMsgButton: React.FC<YesNoMsgButtonProps> = ({ onDidDismiss, message, icon = closeCircle, className = '' }) => {
  
  // The ID of the trigger must be unique in case several instance of that component are present on the same page
  const triggerId = 'present_alertbox_trigger_' + useId();

  return (
    <>
      <IonButton id={triggerId} className={className}>
        <IonIcon icon={icon} />
      </IonButton>
      <IonAlert
        header={message}
        trigger={triggerId}
        buttons={[
          {
            text: 'Oui',
            role: 'confirm',
            handler: () => {
              console.log('Clicked Yes')
            }
          },
          {
            text: 'Non',
            role: 'cancel',
            handler: () => {
              console.log('Clicked No')
            }
          }
        ]}
        onDidDismiss={(event) => {onDidDismiss(event.detail.role === 'confirm')}}
      ></IonAlert>
    </>
  )
}
