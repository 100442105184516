import React from 'react'
import config from '../data/config.json'
import axios from 'axios'
import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonPage, IonRow, IonTitle, IonToolbar, isPlatform, useIonModal, useIonViewDidEnter } from '@ionic/react'
import { arrowBack, flask, logOutOutline, map, personCircle, push, settings } from 'ionicons/icons'
import { type PropsWithChildren, useRef, useState, useId } from 'react'
import { Storage } from '@ionic/storage'
import { YesNoMsgButton } from './YesNoMsgButton'
import './Authentication.css'
import type { AlertData } from './CommonTypes' 

const store = new Storage()
await store.create()

interface LoginProp {
  /** Function called after the user is logged in */
  onGetToken?: undefined | ((token: any) => void)
  /** Reference to the parent modal if this page is displayed in a modal, so that it can be dismissed */
  parentModal?: null | React.RefObject<HTMLIonModalElement>
}

/**
 * A login forms that asks for the username and the password, and
 * check them on the server to get an authentification token that may be
 * later used to display protected content.
 */
export const LoginPage: React.FC<LoginProp> = ({onGetToken = undefined, parentModal = null}) => {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [presentResetPassword, dismissResetPassword] = useIonModal(ResetPasswordPage,{
    dismiss: ()=>{dismissResetPassword()}, 
  })
  const url: string = config.url + 'api/token/'

  /**
   * Checks the credential and get an authenfication token
   * from the server is they are valid.
   * 
   * @returns True if creadential are valid, false otherwise.
   */
  async function handleLogin(): Promise<boolean> {
    if (username === '') {
      setMessage("Veuillez entrer un nom d'utilisateur")
      return false
    }
    if (password === '') {
      setMessage('Veuillez entrer un mot de passe')
      return false
    }
    try {
      const data = {
        data: {
          attributes: {
            username,
            password
          },
          type: 'TokenObtainPairView'
        }
      }
      // If credentials are invalid, then the server will reply
      // with a 401 error page. This will throw an exception and 
      // indicates invalid credentials.
      console.debug('Getting token...')
      document.getElementById('login_page_login_button_id')?.setAttribute('disabled', 'true')

      const response = await axios({
        url,
        data: JSON.stringify(data),
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json'
        }
      })
      await store.set('JWTtoken', response.data.data)
      if (onGetToken !== undefined) {
        onGetToken(response.data)
      }
      console.debug('Token successfully obtained')
      await parentModal?.current?.dismiss()
      return true
    } 
    catch (error) {
      console.debug('Failed to get token')
      setMessage('Identifiant ou mot de passe erroné.')
      document.getElementById('login_page_login_button_id')?.setAttribute('disabled', 'false')
      return false
    }
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => {void parentModal?.current?.dismiss();}} className="back_button">
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Connexion</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className='page_content'> 
        <IonRow>
          <IonCol id="icon_container">
            <IonIcon icon={personCircle} id="person_icon" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonInput
                name="username"
                label="Identifiant"
                labelPlacement="floating"
                onIonChange={(e) => {
                  setUsername(e.detail.value != null ? e.detail.value.toString() : '')
                  setMessage('')
                }}
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonInput
                type="password"
                name="password"
                label="Mot de passe"
                labelPlacement="floating"
                value={password}
                onIonChange={(e) => {
                  setPassword(e.detail.value != null ? e.detail.value.toString() : '')
                  setMessage('')
                }}
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {message !== '' ? <p className="authentication_error">{message}</p> : ''}
            <IonButton expand="block" onClick={() => {void handleLogin()}} id='login_page_login_button_id'>
              Connexion
            </IonButton>
            <p>
              Mot de passe oublié? <a onClick={()=>{presentResetPassword()}}>Réinitialiser le mot de passe</a>
            </p>
          </IonCol>
        </IonRow>
      </IonContent>
    </>
  )
}

/** A password reset page
 * 
 * In two steps: first send the request and get an email with reset code
 * Then use the code to reset the passwords
 */
export const ResetPasswordPage: React.FC<{dismiss: ()=>void}> = ({dismiss}) => {
  const [username, setUsername] = useState<string>('')
  const [password1, setPassword1] = useState<string>('')
  const [password2, setPassword2] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [step, setStep] = useState<1|2|3>(1)
  const url1: string = config.url + 'api/reset_password_1/'
  const url2: string = config.url + 'api/reset_password_2/'

  /** Check that the passwords are correct and identical
   * @argument pass1 first password
   * @argument pass2 second password
   * 
   * @returns an empty string or an error message
   */
  function checkPassword(pass1: string, pass2?: string):string{
    if((pass2!==undefined || pass2!=="") && (pass2!==pass1)){
      return 'Les mot de passe doivent être identiques.'
    }
    if(pass1.length<8){
      return 'Le mot de passe doit contenir au moins 8 caractères'
    }else if(!/^[a-zA-Z0-9.,;@%-_&*{}()]+$/.test(pass1)){
      return 'Le mot de passe doit être composé de chiffres, lettres et des caractères suivants: ".,;@%-_&*{}()".'
    }else if(!/[0-9]/.test(pass1)){
      return 'Le mot de passe doit contenir un chiffre.'
    }else if(!/[A-Z]/.test(pass1)){
      return 'Le mot de passe doit contenir une majuscule.'
    }else if(!/[.,;@%-_&*{}()]/.test(pass1)){
      return 'Le mot de passe doit contenir un caractère spécial.'
    }
    return ''
  }

  async function resetFunction(): Promise<void>{
    document.getElementById('login_page_reset_button')?.setAttribute('disabled', 'true')
    if(step===1){
      if (username === '') {
        setMessage("Veuillez entrer un nom d'utilisateur") 
      }else{
        try{
          const data = {username}
          const response = await axios({
            url:url1,
            data: JSON.stringify(data),
            method: 'POST',
            headers: {
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json'
            }
          })
          console.debug(response)
          setMessage('')
          setStep(2)
        }catch (error) {
          console.debug(error)
          setMessage('Échec lors de la réinitialisation.')
        } 
      }
    }else{
      if (password1 === '' || password2 === '' || message !== '') {
        setMessage('Veuillez entrer un mot de passe valide.')
      }else if(code === ''){
        setMessage("Veuillez entrer le code reçu par email (vérifiez vos spams)")
      }else{
        try{
          const data = {
            password1,
            password2,
            code,
            username,
          }
          const response = await axios({
            url:url2,
            data: JSON.stringify(data),
            method: 'POST',
            headers: {
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json'
            }
          })
          console.debug(response)
          setMessage('')
          setStep(3)
        }catch (error) {
          console.debug(error)
          setMessage('Échec lors de la réinitialisation (phase 2).')
        } 
      }
    }
    document.getElementById('login_page_reset_button')?.setAttribute('disabled', 'false')

  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={()=>{dismiss()}} className="back_button">
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Changer le mot de passe</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className='page_content'> 
        <IonRow>
          <IonCol id="icon_container">
            <IonIcon icon={personCircle} id="person_icon" />
          </IonCol>
        </IonRow>
        {step===3?
          <IonRow>
            <IonCol>
              <IonItem>
                <h4>Réinitialisation du mot de passe effectuée!</h4>
                <p>Vous pouvez maintenant vous (re)connecter:</p>
                <IonButton onClick={dismiss} >
                  Se connecter
                </IonButton>
              </IonItem>
            </IonCol>
          </IonRow>
        :
        <>
        {step===1?
        <IonRow>
          <IonCol>
            <IonItem>
              <IonInput
                name="email"
                label="Email ou identifiant"
                labelPlacement="floating"
                value={username}
                onIonChange={(e) => {
                  setUsername(e.detail.value != null ? e.detail.value.toString() : '')
                  setMessage('')
                }}
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        :
        <>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonInput
                type="password"
                name="password1"
                label="Mot de passe"
                labelPlacement="floating"
                value={password1}
                onIonChange={(e) => {
                  const password = e.detail.value != null ? e.detail.value.toString() : ''
                  setMessage(checkPassword(password,password2))
                  setPassword1(password)
                }}
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonInput
                type="password"
                name="password2"
                label="Répéter le mot de passe"
                labelPlacement="floating"
                value={password2}
                onIonChange={(e) => {
                  const password = e.detail.value != null ? e.detail.value.toString() : ''
                  setMessage(checkPassword(password,password1))
                  setPassword2(password)
                }}
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonInput
                type="text"
                name="code"
                label="Code reçu par email (vérifier les spams)"
                labelPlacement="floating"
                value={code}
                onIonChange={(e) => {
                  const codeTemp:string = e.detail.value != null ? e.detail.value.toString() : ''
                  setCode(codeTemp)
                }}
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        </>
        }
        <IonRow>
          <IonCol>
            {message !== '' ? 
              <p className="authentication_error">{message}</p>
            : ''}
            <IonButton expand="block" onClick={() => {void resetFunction()}}
              id = "login_page_reset_button">
              Envoyer
            </IonButton>
          </IonCol>
        </IonRow>
      </>
      }
      </IonContent>
    </>
  )
}

interface LoginButtonProps {
  /** Function that was used to handle the token at connexion. */
  onGetToken: (token: any) => void
  /** True if the user is already logged in, false otherwise. */
  connected?: boolean
}

/**
 * Login/logout component button that either displays the login form or the disconnect prompt depending on
 * weither the user is already logged in or not.
 * 
 * @param onGetToken  Function that was used to handle the token at connexion.
 * @param connected   True if the user is already logged in, false otherwise.
 *  
 * @returns A fucntionnal component as a button, automatically skinned for login or logout, with associated function.
 */
export const LoginButtons: React.FC<LoginButtonProps> = ({ onGetToken, connected = false }) => {
  const openLoginPageBtnId = 'open_login_page_button_' + useId();  // Ensure unique ID within the app
  const modal = useRef<HTMLIonModalElement>(null)

  async function handleLogout(wantDisconnect: boolean): Promise<void> {
    if (wantDisconnect) {
      await logout()
      onGetToken(null)
    } 
  }

  if (connected) {
    return (
      <YesNoMsgButton 
        className="icon_logout_button" 
        icon={logOutOutline} onDidDismiss={(result: boolean) => {void handleLogout(result)}} 
        message="Voulez vous vous déconnecter ?"
       />)
  }
  else {
    return (
      <>
        <IonButton id={openLoginPageBtnId} className={'icon_login_button'}>
          <IonIcon icon={personCircle} />
        </IonButton>
        <IonModal className="page" trigger={openLoginPageBtnId} ref={modal}>
          <LoginPage onGetToken={onGetToken} parentModal={modal}/>
        </IonModal>
      </>)
  }
}

interface CustomNavButtonProps{
  /** address of redirection */
  href: string
  /** icon */
  icon: string
  /** label of the page */
  label: string
  /** Wether this is the current tab */
  currentTab?: boolean
}

/**
 * A custom navigation button 
 */
const CustomNavButton: React.FC<CustomNavButtonProps> = ({href, icon, label, currentTab=false})=>{
  let myid = "tab-alert-nav-icon"
  if (href==="/tabInterventions"){
    myid = "tab-intervention-nav-icon"
  }else if(href==="/tabData"){
    myid = "tab-data-nav-icon"
  }else if(href==="/tabOptions"){
    myid = "tab-option-nav-icon"
  }

  return (
      <div className={currentTab?"authentication-nav-button authentication-nav-button-current":"authentication-nav-button"}
          onClick={()=>document.getElementById(myid)?.click()}>
        <IonIcon aria-hidden="true" icon={icon} />
        <IonLabel>{label}</IonLabel>
      </div>
    )
}

interface OpenPageProps {
  /** Title of the page */
  title: string
  /** For header buttons */
  headerButtons?: any
  /** id */
  id?: undefined | string
}

interface RequireLoginProps extends OpenPageProps{
  /** If the authMessage is not None, it will be displayed instead of the children components */
  authMessage?: string | undefined
  /** function called on authentication */
  onAuthentication?: (connected: boolean) => any | undefined
}

interface OptionalLoginProps extends RequireLoginProps {
  /** Wether login is required */
  requiredLogin: boolean 
}

/** 
 * A basic page design that can require login to show the main child
 */
export const OptionalLoginPage: React.FC<PropsWithChildren<OptionalLoginProps>> = ({ title, headerButtons, id, authMessage = undefined, onAuthentication, children, requiredLogin}) => {
  const [connected, setConnected] = useState<boolean>(false)
  const desktop = isPlatform("desktop")
  /**
   * Check if the user is already logged in. 
   */
  async function checkConnection(): Promise<void> {
    const logged = await isLogged()
    setConnected(logged)
    if (onAuthentication != null) {
      onAuthentication(logged)
    }
  }

  /**
   * Performs checks every times this protected content page
   * comes into focus, to force re-redering and display or hide the protected content
   * in case the user has logged in or out since the last render.
   */
  useIonViewDidEnter(() => {
    checkConnection()
      .then()
      .catch(console.error)
  }, [])

  return (<IonPage id={id}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start" id="authentication_header_buttons">{headerButtons}</IonButtons>
            {desktop?
            <div id="authentication_tab_bars">
              <CustomNavButton href="/tabAlerts" icon={push} label="Alertes" currentTab={title==="Alertes"} />
              <CustomNavButton href="/tabInterventions" icon={flask} label="Interventions" currentTab={title==="Interventions"} />
              <CustomNavButton href="/tabData" icon={map} label="Données" currentTab={title==="Données"} />
              <CustomNavButton href="/tabOptions" icon={settings} label="Options" currentTab={title==="Infos et options"} />
            </div>
            :
            <IonTitle>{title}</IonTitle>
            }
            {requiredLogin?
            <IonButtons slot="end" id="authentication_login_buttons">
              <LoginButtons
                onGetToken={(token: any) => {
                  setConnected(token !== null)
                }}
                connected={connected}
              />
            </IonButtons>
            :<></>}
          </IonToolbar>
        </IonHeader>
      <IonContent fullscreen>
        <div id="main_page_background" >
          <div id="main_page_content" >
            {requiredLogin && !connected ? 
              <p>{authMessage ?? "Veuillez vous connecter pour accéder à cette page"}</p>
              : 
              children
            }
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}


/** A basic page that requires login */
export const RequireLoginPage: React.FC<PropsWithChildren<RequireLoginProps>> = ({ title, headerButtons, id, authMessage = undefined, onAuthentication, children }) => {
  return <OptionalLoginPage 
    title={title}   
    authMessage={authMessage} 
    headerButtons={headerButtons}
    id={id}
    onAuthentication={onAuthentication}
    requiredLogin={true}
    >
    {children}
  </OptionalLoginPage>
}

/** A basic page without login */
export const OpenPage: React.FC<PropsWithChildren<OpenPageProps>> = ({ title, headerButtons, id, children }) => {
  return <OptionalLoginPage 
    title={title}
    headerButtons={headerButtons}
    id={id}
    requiredLogin={false}
  >
    {children}
  </OptionalLoginPage>
}

/** Get the authorization token, if it exists */
export async function getAuthToken(checkValid: boolean = false): Promise<any> {
  let token = await store.get('JWTtoken')
  if (token === null) {
    return null
  }
  if (checkValid) {
    if (!('access' in token && 'refresh' in token)) {
      console.log('token is null')
      await store.set('JWTtoken', null)
      return null
    }
    const url: string = `${config.url}api/token/check_valid/`
    let validated: string = 'ok'
    const data = {
      data: {
        attributes: { ...token },
        type: 'ValidateAuthTokenView'
      }
    }
    try {
      const response: any = await axios({
        url,
        data: JSON.stringify(data),
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json'
        }
      })
      validated = response.data.data.status
      console.log('Token checked: ' + validated)
    } catch (error) {
      console.log('Error checking token')
      console.log(error)
      validated = 'fail'
    }
    if (validated === 'refresh') {
      token = await renewAuthToken(token)
    } else if (validated !== 'ok') {
      await store.set('JWTtoken', null)
      return null
    }
  }
  return token
}

/** Check if logged in */
export async function isLogged(): Promise<boolean> {
  const token = await store.get('JWTtoken')
  return token !== null
}

/** Refresh the authorization token */
export async function renewAuthToken(token?: any): Promise<any> {
  const url: string = config.url + 'api/token/refresh/'
  if (token === null) {
    token = await store.get('JWTtoken')
  }
  if (token === null) {
    return null
  }
  try {
    const data = {
      data: {
        attributes: {
          refresh: token.refresh
        },
        type: 'TokenRefreshView'
      }
    }
    const response = await axios({
      url,
      data: JSON.stringify(data),
      method: 'POST',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json'
      }
    })
    token.access = response.data.data.access
    await store.set('JWTtoken', token)
    return token
  } catch (error) {
    console.log(error)
    return null
  }
}

/** Log out
 *
 * Actually delete the authorization token
 */
export async function logout(): Promise<boolean> {
  await store.set('JWTtoken', null)
  return true
}

/** Fetch access keys for my alerts 
 * 
 * These are used to mark the alerts sent by the user (even anonymously), so that he can access them.
 */
export async function getAlertAccessKeys():Promise<string[]>{
  const myAlerts : AlertData[] = (await store.get('my_alerts')) ?? []
  const accessKeys : string[] = myAlerts.filter((item:AlertData)=>item.access_key).map((item:AlertData)=>String(item.access_key))
  return accessKeys
}
