import './TabOptions.css'
import React, { useState } from 'react'
import { IonButton } from '@ionic/react'
import logo from '../resources/Logo.png'
import Icon112 from '../resources/112.png'
import { OpenPage } from '../components/Authentication'

/** Return the description of SDE */
const InfoTab = () : JSX.Element => {
  return (
    <div id="more_info">
      <h2>A propos de l'application</h2>
      <p>Environeo est un projet initié en 2019 par le SAMU de l'Environnement Alsace. Cette application a pour but de permettre la production collaborative et participative de données sur les impacts environnementaux, et notamment ceux générant des alertes en urgence.</p>
      <p>Les données collectées sont mises à disposition de la communauté des sentinelles de l'environnement, animée par l'association du SAMU de l'Environnement, implantée à Strasbourg dans la Région Grand Est.</p>
      <p>Des fonctionnalités seront développées au fur à mesure de ce projet conduit grâce à une communauté composée principalement de bénévoles sur leur temps libre. Aussi, nous vous informons que les données transmises ne font pas l'objet à l'heure actuelle, d'une intervention systématique sur le terrain.</p>
      <h2>Informations de contact</h2>
      <p>Pour toute demande d'information concernant la présente application, vous pouvez contacter l'association du SAMU de l'environnement aux coordonnées suivantes:</p>
      <a id="contact" href="https://www.samudelenvironnement.org/accueil/contact">
        contact@samudelenvironnement.org
      </a>
      <h2>Numéro d'urgence</h2>
      <p>Nous vous rappelons qu'en cas d'urgence de sécurité et de santé publique, vous pouvez composer le numéro d'appel unique suivant, valable dans l'ensemble de l'espace européen, ou son équivalent, le 114 pour les personnes sourdes et malentendantes:</p>
      < img src={Icon112} alt="112" id="pic112" />
    </div>
  )
}

/** Main element
 *
 * On button click, the showInfo state changes → the description is toggled
 */
const TabOptions: React.FC = () => {
  const [showInfo, setShowInfo] = useState(false)

  return (
    <OpenPage title="Infos et options">
      <div id="main_content">
        <h1>Environeo</h1>
        <img src={logo} alt="logo" id="logo"></img>
        <h2>Connaître, réagir, anticiper</h2>

        <IonButton onClick={() => { setShowInfo((val) => !val); }} id="show_more">
          En savoir plus
        </IonButton>

        {showInfo ? <InfoTab /> : null}
      </div>
    </OpenPage>
  )
}

export default TabOptions
