/** 
 * Extended IonInput component with additional validation checks 
 */
import React, { type PropsWithChildren, useState, } from 'react'
import { IonInput } from '@ionic/react'
import './InputWithCheck.css'


interface InputWithCheckProps {
  /** Callback function called when input is checked for validation. Passed argument is true if input is valid, false otherwise */
  onValidation?: (ok: boolean) => void,
  /** Check input against the specified format if specified */
  format?: "email" | "tel" | "int" | "number",
  /** Alternatively, a regular expression to check the input */
  regexValidator?: RegExp,
  label?: string,
  required?: boolean,
  labelPlacement?: "fixed" | "start" | "end" | "floating" | "stacked",
  type?: any,
  value?: string,
  className?: string,
  id?: string,
  ref?: React.Ref<HTMLIonInputElement>,
  placeholder?: string,
  onIonChange?: (e: any) => void,
}


/** 
 * Extended IonInput component with additional validation checks 
 */
export const InputWithCheck: React.FC<PropsWithChildren<InputWithCheckProps>> = ({
  onValidation, format, regexValidator, label, required, labelPlacement, type, value, className, id, ref, placeholder, onIonChange, children
  }) => { 
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [requiredMessage, setRequiredMessage] = useState<string>("")
    
  function handleIonChange(e: any): void {
    let text : string = e.detail.value
    let reg : RegExp | undefined = regexValidator;

    if (reg === undefined) {
      if (format === "email") {
        reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
      } else if (format === "tel") {
        reg = /^\+?[\d\s]{8,15}$/
      } else if (format === "int") {
        reg = /^[-+]?\d+$/
      } else if (format === "number") {
        reg = /^[-+]?\d+([.,]\d+)?$/
      }
    }

    // Format of text input must match pattern
    const formatInvalid = (reg !== undefined) && (text !== "") && (!reg.test(text))
    if (formatInvalid) {
      setErrorMessage("Format invalide")
    } else {
      if(format==="number"){
        text = text.replace(",",".")
      }
      setErrorMessage("")
    }

    // Required field must not be empty
    const requiredMissing = (required === true) && (text === "")
    if (requiredMissing) {
      setRequiredMessage("Champs requis")
    } else {
      setRequiredMessage("")
    }

    const fieldValid = (!formatInvalid) && (!requiredMissing)
    if (onValidation !== undefined) {
      onValidation(fieldValid)
    }
    
    if (onIonChange !== undefined) {
      if (!fieldValid){
        text = ""
      }
      e.detail.value = text;
      onIonChange(e);
    }
  }

  return <>
    <IonInput
      label={label}
      required={required}
      labelPlacement={labelPlacement}
      type={type}
      value={value}
      className={className}
      id={id}
      ref={ref}
      placeholder={placeholder}
      onIonChange={handleIonChange}
    >
      {children}
    </IonInput>
    <div className="input_with_check_errors_div">
      {(errorMessage !== "")? <span>{errorMessage}</span> : <></>}
      {(requiredMessage !== "") ? <span>{requiredMessage}</span> : <></>}
    </div>
  </>
}

