import React, { useState, useRef, useEffect, useId } from 'react'
import { InputCustomEvent, IonAlert, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRow, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToolbar, SelectChangeEventDetail, useIonModal } from '@ionic/react'
import { add, close as closeCircle, checkmarkOutline, refresh, checkmark, closeCircleOutline, pencil, arrowDown, arrowUp } from 'ionicons/icons'
import './InterventionComponents.css'
import parameterList0 from '../data/parameters.json'
import { GeoCard, GeoInput } from './GeoCard'
import { Location, Parameter, Measurement, Analysis, Intervention, AlertData, CustomDocument, AlertDescriptionNode } from './CommonTypes'
import { Storage } from '@ionic/storage'
import config from '../data/config.json'
import axios from 'axios'
import { YesNoMsgButton } from './YesNoMsgButton'
import { getAuthToken } from './Authentication'
import { allTrue, toIsoString, getDescriptionNodes } from './CommonFunctions'
import { InputWithCheck } from '../components/InputWithCheck'
import { data } from 'cypress/types/jquery'

const store = new Storage()
await store.create()


/** Get a list of measurement parameters */
async function getParameterList():Promise<Parameter[]>{
  const url: string = `${config.url}api/parameter/`
  let parameters:Parameter[]=[]
  const response = await axios.get(url)
  try{
    parameters = response.data.data.map((item:any)=>{
      return new Parameter({"id":item.id,...item.attributes})
    })
    store.set("parameter_list",parameters)
  }catch{
    try{
      parameters = await store.get("parameter_list")
      parameters = parameters.map((item:any)=>new Parameter(item))
    }catch{
      parameters = parameterList0.map((item:any)=>new Parameter(item))
    }
  }
  return parameters
}

interface FileProp {
  /** Function to set the files in intervention.documents */
  setParentFiles: (documentSet: CustomDocument[]) => void;
  /** Initial document set */
  documentSet0: CustomDocument[]
}

/** Component to input files
 *
 * To set intervention.document_set:
 * display a list of the loaded files and store them into local memory
 */
export const FileComp: React.FC<FileProp> = ({ setParentFiles, documentSet0 }) => {
  const [myFiles, setMyFiles] = useState<CustomDocument[]>([...documentSet0])
  /** On file change in the input, add the file to memory */
  function onFileChange(ev: any) {
    const doc = new CustomDocument({
      "file":ev.target.files[0],
      "delete":false,
      "name":ev.target.files[0].name,
    })
    const newDocumentSet = [...myFiles, doc]
    setMyFiles(newDocumentSet)
    setParentFiles(newDocumentSet)
  }

  /** Delete a file from the memory */
  function deleteFile(num: number) {
    const newDocumentSet = [...myFiles]
    newDocumentSet[num].delete = true
    setMyFiles(newDocumentSet)
    setParentFiles(newDocumentSet)
  }

  const button_id = 'file-upload'+useId()

  /** Open the file dialog by clicking on the hidden 'file' input */
  function openFileDialog() {
    (document as any).getElementById(button_id).click()
  }

  return (
    <div id="file_list">
      {myFiles.map((file, num) => {
        if (file.delete) {
          return undefined
        }
        return (
          <div className="file_div" key={'file' + num}>
            <IonButton className="intervention-edit-sub">{file.name}</IonButton>
            <IonButton className="button_icon intervention-remove-sub" onClick={() => { deleteFile(num); }}>
              <IonIcon icon={closeCircle}/>
            </IonButton>
          </div>
        )
      })}
      <input type="file" id={button_id} style={{ display: 'none' }} onChange={(ev) => { onFileChange(ev); }}></input>
      <IonButton onClick={openFileDialog} className="button_icon_centered">
        <IonIcon icon={add}></IonIcon>
      </IonButton>
    </div>
  )
}


interface MeasurementButtonProps{
  /** A measurement object */
  measurement: Measurement
  /** Index of the measurement in analysis.measurement_set */
  num: number
  /** Index of the analysis in intervention.analysis */
  numAnalysis: number
  /** Function to set the measurement in analysis.measurement_set */
  setMeasurement: Function
  /** Function to remove the measurement from analysis.measurement_set */
  removeMeasurement: Function
}


export const EditMeasurementButton: React.FC<MeasurementButtonProps> = ({measurement,num,numAnalysis,setMeasurement,removeMeasurement}) => {
  const [present, dismiss] = useIonModal(MeasurementModal, {
    measurement,
    num,
    numAnalysis,
    setMeasurement,
    removeMeasurement,
    dismiss: () => { dismiss(); },
  });

  
  /** String description of a measurement */
  function describe(m: Measurement) {
    if (m.parameter?.name === undefined || m.parameter?.unit === undefined || m.value === undefined) {
      return <p className="subdescription0">Mesure incomplète</p>
    }
    return (
      <p className="subdescription">
        {m.parameter.name}: {m.value} {m.parameter.unit}
      </p>
    )
  }

  return  (<>
  <div className="measurement_buttons">
    <IonButton className="intervention-edit-sub" onClick={()=>{ present(); }}>
      {'Mesure #' + (num + 1)}
    </IonButton>
    <IonButton onClick={() => {removeMeasurement(num)}} 
      className="button_icon intervention-remove-sub">
      <IonIcon icon={closeCircle} />
    </IonButton>
  </div>
  {describe(measurement)}
  </>)
}

interface MeasurementModalProps extends MeasurementButtonProps{
  dismiss: () => any;
}

/** Measurement component: form to enter a measurement parameter, value and date */
export const MeasurementModal: React.FC<MeasurementModalProps> = ({ measurement, num, numAnalysis, setMeasurement, removeMeasurement, dismiss}) => {
  const [myMeasurement, setMyMeasurement] = useState<Measurement>(measurement)
  const [showCustomParameter, setShowCustomParameter] = useState<boolean>((myMeasurement.parameter?.id === undefined)||(myMeasurement.parameter?.id === "-1"))
  const [parameterList,setParameterList] = useState<Parameter[]>([])
  const [validatedFields, setValidatedFields] = useState<Record<string, boolean>>({});

  /** Check wich parameter is selected
   *
   * If "Autre paramètre" is selected, show the form to indicate name and unit of the new parameter
   */
  function checkParameter(e:any) {
    const id:string= String(e.detail.value)
    let ok = false
    parameterList.forEach((par) => {
      if (par.id == id) {
        myMeasurement.parameter = par
        ok = true
      }
    })
    myMeasurement.parameter = new Parameter({...myMeasurement.parameter})
    myMeasurement.parameter.id = ok?id:"-1"
    setShowCustomParameter(!ok)
  }

  /** Return the measurement data and quit the modal */
  function confirm():void {
    if(!allTrue(validatedFields)){
      return 
    }
    dismiss()
  }

  /** Return a clean string value, not undefined */
  function cleanUndefined(text: any): string {
    let text2:string = String(text)
    if (text2 === 'undefined') {
      return ''
    } else {
      return text2
    }
  }

  /** Fetch the available parameters from the database */
  useEffect(() => {
    async function getParameters() {
      const parameters: Parameter[] = await getParameterList();
      setParameterList(parameters)
      if(parameters.length>=1){
      myMeasurement.parameter = new Parameter(parameters[0])
      setShowCustomParameter(false)
      }
    }
    getParameters()
  }, [])

  /** Update 'myMeasurement' from input */
  function setArg(argName: keyof Parameter) {
    return (e: any) => {
      if(myMeasurement.parameter===undefined){
        myMeasurement.parameter = new Parameter({id:"-1"})
      }
      myMeasurement.parameter[argName] = e.detail.value ?? ''
      setMyMeasurement(myMeasurement)
    }
  }


  return (
  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonTitle>Mesure #{num + 1}</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={() => { confirm(); }}>
            <IonIcon icon={checkmarkOutline}></IonIcon>
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="page_content">
      <IonItem key="parameter">
        <div className="measurement-item-content">
          <IonSelect labelPlacement="stacked" label="Paramètre" value={myMeasurement.parameter?String(myMeasurement.parameter.id):"-1"} onIonChange={checkParameter}>
            {parameterList.map((parameter) => {
              return (
                <IonSelectOption value={parameter.id} key={String(parameter.id)}>
                  {parameter.name} ({parameter.unit})
                </IonSelectOption>
              )
            })}
            <IonSelectOption value="-1" key="-1">
              Autre paramètre
            </IonSelectOption>
          </IonSelect>
        </div>
      </IonItem>
      {showCustomParameter ? (
        <IonItem key="new parameter">
          <div className="measurement-item-content">
            <h4>Nouveau paramètre</h4>
            <IonInput label="nom" labelPlacement="stacked" type="text" value={cleanUndefined(myMeasurement.parameter?.name)} onIonChange={setArg("name")} />
            <IonInput label="unité" labelPlacement="stacked" type="text" value={cleanUndefined(myMeasurement.parameter?.unit)} onIonChange={setArg("unit")} />
            <IonInput label="description" labelPlacement="stacked" type="text" value={cleanUndefined(myMeasurement.parameter?.description)} onIonChange={setArg("description")} />
          </div>
        </IonItem>
      ) : (
        ''
      )}
      <IonItem key="value" style={{"--padding-bottom":"2vw"}} >
        <div className="measurement-item-content">
          <div className="block_div">
            <InputWithCheck format='number' onValidation={(ok:boolean) => {validatedFields.value = ok}} 
              onIonChange={(e)=>{
                myMeasurement.value = parseFloat(String(e.detail.value).replace(",","."));
                setMyMeasurement(new Measurement({...myMeasurement}))
              }} 
              label="Valeur" labelPlacement="stacked" type="text" value={cleanUndefined(myMeasurement.value)} />
          </div>
          <IonDatetimeButton datetime={'datetime' + num + '-' + numAnalysis}></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonDatetime id={'datetime' + num + '-' + numAnalysis} onIonChange={(e) => (myMeasurement.date = new Date(String(e.detail.value)))} value={toIsoString(myMeasurement.date ? myMeasurement.date : new Date())}></IonDatetime>
          </IonModal>
        </div>
      </IonItem>
    </IonContent>
  </IonPage>
  )
}

interface AnalysisButtonProps{
  /** Analysis object */
  analysis: Analysis
  /** Index of the analysis in intervention.analysis */
  num: number
  /** Function to set the analysis in intervention.analysis */
  setAnalysis: Function
  /** Function to remove the analysis from intervention.analysis */
  removeAnalysis: Function
}

export const EditAnalysisButton: React.FC<AnalysisButtonProps> = ({analysis,num,setAnalysis,removeAnalysis}) => {
  const [present, dismiss] = useIonModal(AnalysisModal, {
    analysis,
    num,
    setAnalysis,
    removeAnalysis,
    dismiss: () => { dismiss(); },
  });

  return  <div className="analysis_buttons">
    <IonButton className="intervention-edit-sub" onClick={()=>{ present(); }}>
      {analysis.name}
    </IonButton>
    <IonButton
      className="button_icon intervention-remove-sub"
      onClick={() => {
        removeAnalysis(num)
      }}
    >
      <IonIcon icon={closeCircle} />
    </IonButton>
  </div>
}

interface AnalysisModalProp extends AnalysisButtonProps{
  dismiss: () => any;
}



/** Analysis component: form to register an analysis
 *
 * An intervention is composed of multiple analysis, each one with various measurements
 */
export const AnalysisModal: React.FC<AnalysisModalProp> = ({ analysis, num, setAnalysis, removeAnalysis, dismiss}) => {
  const [myAnalysis, setMyAnalysis] = useState<Analysis>(analysis)
  const [locationString, setLocationString] = useState<String>(analysis.location?analysis.location.pprint():"")
  function confirm() {
    setAnalysis(myAnalysis, num)
    dismiss()
  }

  /** Set the measurement (in myAnalysis.measurement_set) */
  function setMeasurement(measurement: Measurement, num: number) {
    setMyAnalysis((ana) => {
      const ana2: any = {}
      Object.assign(ana2, ana)
      ana2.measurement_set[num] = measurement
      return ana2
    })
  }

  /** Remove the measurement (from myAnalysis.measurement_set) */
  function removeMeasurement(num: number) {
    setMyAnalysis((ana: Analysis) => {
      const ana2: any = {}
      Object.assign(ana2, ana)
      ana2.measurement_set[num] = undefined
      return ana2
    })
  }

  /** Set the location (in myAnalysis.location) */
  function setLocation(location: Location):void {
    let location2 : Location = new Location(location)
    myAnalysis.location = location2
    setMyAnalysis(new Analysis(myAnalysis))
    setLocationString(location2.pprint())
  }

  return (
    <span>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle> Analyse #{num + 1} </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => { confirm(); }}>
                <IonIcon icon={checkmarkOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem key="item1">
            <div className="analysis-item-content">
              <IonInput
                label="Nom"
                labelPlacement="stacked"
                type="text"
                value={myAnalysis.name}
                onIonChange={(e: any) => {
                  myAnalysis.name = String(e.detail.value??"")
                }}
              />
            </div>
          </IonItem>
          <IonItem key="item2" className="geolocationItem">
            <div className="analysis-item-content">
              <h4> Localiser </h4>
              <div className="intervention_index_div">
                <GeoCard setLocation={setLocation} icon={true} data={myAnalysis.location} />
                <div id="analysis_location_string">
                  {/*locationString != "" ? <p>{locationString}</p> : <i>Position requise</i>*/}
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <p className='intervention_analysis_coord_label1'>Longitude</p>
                        <InputWithCheck 
                          className='intervention_analysis_coord_field'
                          id='intervention_analysis_coord_field1'
                          format="number"
                          value={myAnalysis.location.longitude?myAnalysis.location.longitude.toFixed(6):""}
                          onIonChange={(e)=>{
                            myAnalysis.location.longitude=Number(e.detail.value)
                            setMyAnalysis(new Analysis(myAnalysis))
                          }}
                        />
                      </IonCol>
                      <IonCol>
                        <p className='intervention_analysis_coord_label2'>Latitude</p>
                        <InputWithCheck 
                          className='intervention_analysis_coord_field'
                          id='intervention_analysis_coord_field2'
                          format="number"
                          value={myAnalysis.location.latitude?myAnalysis.location.latitude.toFixed(6):""}
                          onIonChange={(e)=>{
                            myAnalysis.location.latitude=Number(e.detail.value)
                            setMyAnalysis(new Analysis(myAnalysis))
                          }}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonTextarea labelPlacement="stacked" label='Commentaire' 
                        value={myAnalysis.location.other??""} 
                        onIonChange={(e)=>{
                          myAnalysis.location.other=e.detail.value??""
                          setMyAnalysis(new Analysis(myAnalysis))
                        }}
                      />
                    </IonRow>
                  </IonGrid>
                </div>
              </div>
            </div>
          </IonItem>
          <IonItem key="item3">
            <div className="analysis-item-content">
              <h4> Mesures </h4>
              <div id="measurement_list">
                {myAnalysis.measurement_set.map((measurement: Measurement | undefined, num2: number) => {
                  if (measurement === undefined) {
                    return undefined
                  }
                  return <EditMeasurementButton key={'measurement_' + num2} measurement={measurement} num={num2} numAnalysis={num} setMeasurement={setMeasurement} removeMeasurement={removeMeasurement} />
                })}
              </div>
              <IonButton
                className="button_icon_centered"
                onClick={() => {
                  setMyAnalysis((ana: Analysis) => {
                    const ana2: any = {}
                    Object.assign(ana2, ana)
                    const newMeasurement: Measurement = new Measurement({
                      value: undefined,
                      parameter: undefined,
                      date: undefined
                    })
                    ana2.measurement_set = [...ana.measurement_set, newMeasurement]
                    return ana2
                  })
                }}
              >
                <IonIcon icon={add}></IonIcon>
              </IonButton>
            </div>
          </IonItem>
        </IonContent>
      </IonPage>
    </span>
  )
}


interface InterventionButtonProps{
  intervention0?: Intervention|undefined;
  icon?: boolean;
  setParentIntervention?: (intervention: Intervention) => any;
}

export const EditInterventionButton: React.FC<InterventionButtonProps> = ({intervention0,icon,setParentIntervention}) => {
  const [present, dismiss] = useIonModal(EditInterventionModal, {
    intervention0,
    dismiss: () => { dismiss(); },
    setParentIntervention
  });
  const edit: boolean = intervention0!==undefined;

  return <IonButton onClick={()=>{ present(); }}>
    {icon?<IonIcon icon={pencil} />:
    edit?"Modifier l'intervention":"Nouvelle Intervention"}
</IonButton>
}


interface InterventionModalProps{
  intervention0: Intervention|undefined;
  dismiss: () => any;
  setParentIntervention?: (intervention: Intervention) => any;
}

/** Main element: form to register an intervention in the form of:
 *
 * name, responsible person, date, location, documents
 * analysis_set:
 *    name, location
 *    mesures:
 *        parameter, value, date
 */
export const EditInterventionModal: React.FC<InterventionModalProps> = ({intervention0,dismiss,setParentIntervention}) => {
  const edit: boolean = intervention0!==undefined;
  let intervention1: Intervention;
  if(edit){
    intervention1 = new Intervention(intervention0)
  }else{
    intervention1 = new Intervention({
      name: '',
      username: '',
      location: undefined,
      alertId: 0,
      date: new Date(),
      creationDate: new Date(),
      comment: '',
      analysis_set: [
        new Analysis({"name":"Analyse #1"}),
      ],
      document_set: [],
      saved: false
    })
  }
  const [intervention, setIntervention] = useState<Intervention>(new Intervention(intervention1))
  const [analysisMax, setAnalysisMax] = useState<number>(intervention1.analysis_set.length)
  const [viewMoreDetails, setViewMoreDetails] = useState<boolean>(false)
  const [alerts, setAlerts] = useState<AlertData[]>([])

  /** Fetch the alerts from the database */
  useEffect(() => {
    async function getAlerts() {
      const token = await getAuthToken(true);
      const myConfig = {
        headers: {
          Authorization: 'Bearer ' + token.access
        }
      }
      const url: string = `${config.url}api/alert_list/`
      const response = await axios.get(url, myConfig)
      const alertsTemp: AlertData[] = [...response.data]
      const descriptionNodes = await getDescriptionNodes()
      const alertTree : Record<number, AlertDescriptionNode> = {}
      descriptionNodes.forEach((item:AlertDescriptionNode)=>{
        alertTree[item.id] = item
      })
      alertsTemp.forEach((item:AlertData)=>{
        if (item.description!==undefined){
          item.description = item.description.map((node:AlertDescriptionNode):AlertDescriptionNode=>{
            if(node.id in alertTree){
              return alertTree[node.id]
            }else{
              return new AlertDescriptionNode({})
            }
          })
        }
      })
      setAlerts(alertsTemp)
    }
    getAlerts()
  }, [])

  /** Store the analysis in the state */
  function setAnalysis(analysis: Analysis, num: number) {
    setIntervention((inter: Intervention) => {
      const inter2: any = {}
      Object.assign(inter2, inter)
      inter2.analysis_set[num] = analysis
      return inter2
    })
  }

  /** add an analysis
   * 
   * To intervention.analysis_set
   */
  function addAnalysis():void{
    setIntervention((inter: Intervention):Intervention => {
      const inter2: any = {}
      Object.assign(inter2, inter)
      const newAnalysis: Analysis = {
        name: 'Analyse #' + (analysisMax + 1),
        location: new Location(undefined),
        measurement_set: []
      }
      inter2.analysis_set = [...inter.analysis_set, newAnalysis]
      return inter2
    })
    setAnalysisMax((n:number):number => n + 1)
  }

  /** Remove the analysis
     * 
    * From intervention.analysis_set
    * If this was the last analysis, create a new empty analysis, 
    * So that there are always at least one analysis per intervention
    * @param num index of the analysis in intervention.analysis_set
  */
  function removeAnalysis(num: number):void {
    setIntervention((inter: Intervention):Intervention => {
      const inter2: any = {}
      Object.assign(inter2, inter)
      inter2.analysis_set[num] = undefined
      // If there are no analysis, add one!
      if (inter2.analysis_set.filter((item:Analysis|undefined)=>item!==undefined).length===0){
        const newAnalysis: Analysis = {
          name: 'Analyse #1',
          location: new Location(undefined),
          measurement_set: []
        }
        inter2.analysis_set = [newAnalysis]
        setAnalysisMax(1)
      }
      return inter2
    })
  }

  /** Close the modal */
  function closeModal() {
    setIntervention(new Intervention(intervention1))
    dismiss()
  }

  /** Update 'intervention' from input */
  function setArg(argName: keyof Intervention) {
    const intervention2: any = intervention
    return (e: any) => {
      intervention2[argName] = e.detail.value ?? ''
    }
  }

  const datetimeId: string = "datetime_" + useId()
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{edit ? "Modifier l'intervention" : "Nouvelle Intervention"}</IonTitle>
          <IonButtons slot="end">
          <YesNoMsgButton
            icon={closeCircle}
            message="Voulez-vous retourner au menu d'accueil ?"
            onDidDismiss={(ok: boolean) => {
              if (ok) {
                closeModal()
              }
            }}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='page_content' id="edit_intervention_content">
        <IonItem key="main">
          <div className="intervention-item-content">
            <h4>Détails </h4>
            <IonInput labelPlacement="stacked" 
              label="Nom de l'intervention" 
              type="text" 
              onIonChange={setArg('name')} 
              value={intervention.name}/>
            {viewMoreDetails?
            (<>
              <IonSelect 
                labelPlacement="stacked" 
                label="En réponse à une alerte?" 
                value={intervention.alertId??undefined} 
                onIonChange={setArg('alertId')}>
                <IonSelectOption value={undefined} key="-1">
                  {' '}
                  ---{' '}
                </IonSelectOption>
                {alerts.map((alert) => {
                  const name = (new AlertData(alert)).toShortStringDescription()
                  return (
                    <IonSelectOption value={alert.id} key={String(alert.id)}>
                      {name}
                    </IonSelectOption>
                  )
                })}
              </IonSelect>
              <IonInput labelPlacement="stacked" label="Référent" type="text" onIonChange={setArg('username')} value={intervention.username} />
              <IonInput labelPlacement="stacked" label="Commentaire" type="text" onIonChange={setArg('comment')} value={intervention.comment} />
              <IonDatetimeButton datetime={datetimeId}></IonDatetimeButton>
              <IonModal keepContentsMounted={true}>
                <IonDatetime 
                  id={datetimeId} 
                  onIonChange={(e) => (intervention.date = new Date(String(e.detail.value)))} 
                  value={toIsoString(intervention.date ? intervention.date : new Date())}>
                </IonDatetime>
              </IonModal>
              <IonButton
                id="show_less_details"
                className="button_icon_centered"
                onClick={()=>(setViewMoreDetails(false))}
              >
                <IonIcon icon={arrowUp}></IonIcon>
              </IonButton>
            </>):<>
              <IonButton
                id="show_more_details"
                className="button_icon_centered"
                onClick={()=>(setViewMoreDetails(true))}
              >
                <IonIcon icon={arrowDown}></IonIcon>
              </IonButton>
            </>}
          </div>
        </IonItem>

        <IonItem key="analyses">
          <div className="intervention-item-content">
            <h4>Analyses</h4>
            <div id="analyses_list">
              {intervention.analysis_set.map((analysis: Analysis | undefined, num: number) => {
                if (analysis === undefined) {
                  return undefined
                } else {
                  return <EditAnalysisButton analysis={analysis} num={num} setAnalysis={setAnalysis} removeAnalysis={removeAnalysis} key={'analysis_' + num} />
                }
              })}
            </div>
            <IonButton
              id="add_analysis"
              className="button_icon_centered"
              onClick={addAnalysis}
            >
              <IonIcon icon={add} id="add_analysis_icon"></IonIcon>
            </IonButton>
          </div>
        </IonItem>

        <IonItem key="documents">
          <div className="intervention-item-content">
            <h4>Documents</h4>  
            <div className="intervention_index_div">
              <FileComp
                setParentFiles={(document_set: CustomDocument[]) => {
                  intervention.document_set = document_set
                }}
                documentSet0={intervention.document_set}
              />
            </div>
          </div>
        </IonItem>

        <IonButton
          id="intervention_save_button"
          onClick={async () => {
            document.getElementById('intervention_save_button')?.setAttribute('disabled', 'true')
            try{
              await saveIntervention(intervention)
              if(setParentIntervention!==undefined){
                setParentIntervention(intervention)
              }
            }finally{
              document.getElementById('intervention_save_button')?.setAttribute('disabled', 'false')
              dismiss()
            }
          }}
        >
          Enregistrer
        </IonButton>
      </IonContent>
    </IonPage>
  )
}

/** Save an intervention file
 *
 * Do it separately to use the multipart/form-data
 *
 * @param: [File] file a file
 * @param: [number] interventionId the id of the intervention
 */
async function saveInterventionFile(doc: CustomDocument, interventionId: number) : Promise<{saved:boolean, doc:CustomDocument}> {
  // If the file existed and was deleted, delete it
  if(doc.id !== undefined && doc.delete && doc.intervention_id !== undefined){
    const token = await getAuthToken(true)
    if (token === null) {
      return {saved:false, doc}
    }
    const configuration = {
      headers: { Authorization: `Bearer ${token.access}` }
    }
    const url = `${config.url}/api/document_delete/${doc.id}/${doc.intervention_id}/`
    try{
      await axios.delete(url, configuration)
      doc.id = undefined
      return {saved: true, doc}
    }catch{
      return {saved: false, doc}
    }
  }
  // Save a new file if it doesn't exist already, has a file, and wasn't deleted
  if(doc.id === undefined && doc.file !== undefined && !doc.delete){
    const formData = new FormData()
    formData.append('file', doc.file, doc.name)
    formData.append('intervention_id', `${interventionId}`)
    const url = `${config.url}/api/document/`
    try {
      const response = await axios({
        url,
        data: formData,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      console.log({data:response.data})
      const myDoc:CustomDocument = new CustomDocument(response.data)
      myDoc.file = doc.file
      return {saved:true, doc: myDoc}
    } catch {
      return {saved:false, doc:new CustomDocument({delete: true})}
    }
  }
  return {saved: true, doc}
}

/** Save an intervention in JSON format
 *
 * This is not valid for image and document_set,
 * which must be sent in multipart/form-data
 *
 * @param [Intervention] data the intervention data
 * @param [number] localIndex the index of the intervention, null if it has not been saved locally yet
 */
async function saveIntervention(data: Intervention, localIndex: number | undefined = undefined): Promise<any> {
  const edit: boolean = data.id !== undefined;
  const url: string = edit ? `${config.url}api/intervention_detail/${data.id}/`:`${config.url}api/intervention/`
  let myInterventions = (await store.get('myInterventions')) ?? []
  const normalData: any = { ...data }
  const interventionFiles = data.document_set ?? []
  normalData.document_set = []
  normalData.files_number = interventionFiles.filter((item) => !item.delete).length
  normalData.alert = {"id": normalData.alertId}

  /** Remove null entries */
  function remove_nulls() {
    normalData.analysis_set = normalData.analysis_set.filter((el: Analysis | null) => {
      return el !== null && el !== undefined
    })
    for (const analysis of normalData.analysis_set) {
      analysis.measurement_set = analysis.measurement_set.filter((el: Measurement | null) => {
        return el !== null && el !== undefined
      })
    }
  }
  remove_nulls()

  /** Adapt measurements' parameters */
  function clean_parameters() {
    for (const analysis of normalData.analysis_set) {
      for (const measurement of analysis.measurement_set) {
        const par = measurement.parameter
        if (par !== undefined){
          measurement.par_name = par.name
          measurement.par_unit = par.unit
          // Textual description lentgh is arbitrarly limited
          if (par.description.length > 100){
            measurement.par_description = par.description.slice(0, 97) + "..."
          } else {
            measurement.par_description = par.description
          }
          par.parameter_id = par.id
        }
      }
    }
  }
  clean_parameters()

  let msg: string = "Échec lors de la sauvegarde de l'intervention. Veuillez réessayer ultérieurement."
  const token = await getAuthToken(true)
  if (token === null) {
    msg = "Échec lors de la sauvegarde de l'intervention. Veuillez vous reconnecter avant de réessayer."
    return {
      success: false,
      interventions: myInterventions,
      message: msg,
      disconnected: true
    }
  }
  const myheaders = {
    headers: { Authorization: `Bearer ${token.access}` }
  }
  let response: any
  try {
    if (edit){
      response = await axios.put(url, normalData, myheaders)
    } else {
      response = await axios.post(url, normalData, myheaders)
    }
    data.saved = true
  } catch (error) {
    console.log(`Failed to save intervention (${error})`)
    data.saved = false
  }
  if (data.saved) {
    for (const file of interventionFiles) {
      const saveDocResult = await saveInterventionFile(file, response.data.data.id)
      data.saved = data.saved && saveDocResult.saved
    }
  }
  if (localIndex == undefined) {
    myInterventions = [...myInterventions, data]
    await store.set('myInterventions', myInterventions)
  } else {
    myInterventions[localIndex] = data
    await store.set('myInterventions', myInterventions)
  }
  return {
    success: data.saved,
    interventions: myInterventions,
    message: data.saved ? 'Intervention sauvegardée' : msg,
    disconnected: false
  }
}

/** Delete an Intervention from local memory */
async function deleteIntervention(localIndex: number): Promise<any> {
  const myInterventions = (await store.get('myInterventions')) ?? []
  const myNewInterventions = [...myInterventions]
  myNewInterventions.splice(localIndex, 1)
  await store.set('myInterventions', myNewInterventions)
  return { success: true, interventions: myNewInterventions }
}

interface InterventionCardProps {
  data: Intervention
  index: number
  setInterventions: Function
}

export const InterventionCard: React.FC<InterventionCardProps> = ({ data, index, setInterventions }) => {
  const [saving, setSaving] = useState(false)
  const [message, setMessage] = useState('')
  const [savingError, setSavingError] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<boolean>(false);

  if (data === undefined){
    return <></>
  }
  const intervention = new Intervention(data)
  return (
    <IonItem>
      <div className="alert_card_left">
        {intervention.shortDescription()}
      </div>
      {saving ? (
        <IonLoading message="Envoi en cours..."></IonLoading>
      ) : (
        <div className="alert_card_right">
          {data.saved === true ? (
            <IonButton className="button_icon_ok">
              <IonIcon icon={checkmark}></IonIcon>
            </IonButton>
          ) : (
            <>
              <IonButton
                className="button_icon"
                onClick={async () => {
                  setSaving(true)
                  try {
                    const res = await saveIntervention(data, index)
                    setInterventions(res.interventions)
                    setMessage(res.message)
                  } catch {
                    setSavingError(true)
                  }
                  setSaving(false)
                }}
              >
                <IonIcon className="" icon={refresh}></IonIcon>
              </IonButton>
              <IonAlert isOpen={savingError}
                header="Erreur à l'enregistrement"
                message="L'intervention n'a pas pu être enregistrée."
                buttons={['Fermer']}
                onDidDismiss={() => { setSavingError(false); }}
              />
              <IonButton
                className = "button_icon_danger"
                onClick={async () => {
                  setSaving(true)
                  try {
                    const res = await deleteIntervention(index)
                    setInterventions(res.interventions)
                  } catch {
                    setDeleteError(true)
                  }
                  setSaving(false)
                }}
              >
                <IonIcon className="" icon={closeCircleOutline}></IonIcon>
              </IonButton>
              <IonAlert isOpen={deleteError}
                header="Erreur"
                message="L'intervention n'a pas pu être supprimée."
                buttons={['Fermer']}
                onDidDismiss={() => { setDeleteError(false); }}
              />
              <IonAlert isOpen={message !== ''} header="Message" message={message} buttons={['Fermer']} onDidDismiss={() => { setMessage(''); }}></IonAlert>
            </>
          )}
        </div>
      )}
    </IonItem>
  )
}
