import React, { useEffect, useState } from 'react'
import './TabInterventions.css'
import { EditInterventionButton, InterventionCard } from '../components/InterventionComponents'
import { RequireLoginPage } from '../components/Authentication'
import { type Intervention } from '../components/CommonTypes'
import { Storage } from '@ionic/storage'
import { IonList } from '@ionic/react'

const store = new Storage()
await store.create()

/** Main element display InterventionCard
 *
 * form to register an intervention in the form of:
 *
 * name, responsible person, date, location, documents
 * analyses:
 *    name, location
 *    mesures:
 *        parameter, value, date
 */
const TabInterventions: React.FC = () => {
  const [myInterventions, setMyInterventions] = useState<Intervention[]>([])

  useEffect(() => {
    async function getInterventions(): Promise<void> {
      const myInterventionsTemp: Intervention[] = (await store.get('myInterventions')) ?? []
      setMyInterventions(myInterventionsTemp)
    }
    getInterventions()
      .catch(console.error)
  }, [])

  const sent: Array<[Intervention, number]> = []
  const notSent: Array<[Intervention, number]> = []

  myInterventions.forEach((data, index) => {
    if (data.saved === true) {
      sent.push([data, index])
    } else {
      notSent.push([data, index])
    }
  })

  function sortByDate(tab: Array<[Intervention, number]>): void {
    tab.sort((a, b) => {
      const aa = a[0].date
      const bb = b[0].date

      if (aa !== undefined && bb !== undefined) {
        return -aa.getTime() + bb.getTime() 
      } else if (bb !== undefined) {
        return 1
      } else {
        return -1
      }
    })
  }
  sortByDate(sent)
  sortByDate(notSent)

  return (
    <RequireLoginPage title="Interventions" authMessage="Veuillez vous connecter pour accéder à cette page">
      <EditInterventionButton />
      {notSent.length > 0 ? (
        <>
          <h4>Interventions en attentes</h4>
          <IonList>
            {notSent.map((prop, num) => (
              <InterventionCard data={prop[0]} index={prop[1]} key={`my_interventions_${num}`} setInterventions={setMyInterventions} />
            ))}
          </IonList>
        </>
      ) : (
        ''
      )}
      {sent.length > 0 ? (
        <>
          <h4>Interventions envoyées</h4>
          <IonList>
            {sent.map((prop, num) => (
              <InterventionCard data={prop[0]} index={prop[1]} key={`my_interventions_${num}`} setInterventions={setMyInterventions} />
            ))}
          </IonList>
        </>
      ) : (
        ''
      )}
    </RequireLoginPage>
  )
}

export default TabInterventions
