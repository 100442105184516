/**
 * Common types shared accross several components of the app.
 */


/** 
 * Represent a location.
 */
export class Location {
  /** Longitude, in degrees */
  longitude?: number
  /** Latitude, in degrees */
  latitude?: number
  /** Additionnal hint to precise the location */
  other?: string

  constructor(data: any) {
    if (data != null) {
      if (data.longitude) {
        this.longitude = data.longitude
      }
      if (data.latitude) {
        this.latitude = data.latitude
      }
      if (data.other) {
        this.other = data.other
      }
    }
  }

  /** Wether the location has a longitude and latitude */
  complete(): boolean {
    return (this.longitude !== undefined) && (this.latitude !== undefined)
  }

  /** Pretty print of the location */
  pprint(): string {
    let temp: string = ''
    if (this.longitude && this.latitude) {
      temp = `(${this.longitude.toFixed(4)},${this.latitude.toFixed(4)})`
    }

    return temp
  }
}


/**
 * Represent a node in the description tree of an Alert.
 * A node is an element of description that describes or qualify the situation observed.
 * Nodes are organized as tree in order to refine the diagnosis by asking for more precise elements
 * as the user moves in the tree (ie. in the description process)
 */
export class AlertDescriptionNode {
  id: number = -1           /* Unique ID of the node */
  name: string = ""         /* Human readable and self-descriptive name */
  level: string = "1"       /* Depth level in the description tree */
  parents: number[] = []    /* IDs of parents this node can be reached from */

  /** 
   * Constructor
   * 
   * @param data dictionary possibly containing data to fill in the object AlertDescriptionNode
   */
  constructor(data: any) {
    if (data.id) {
      this.id = typeof(data.id) === 'string' ? parseInt(String(data.id)) : data.id
    }
    if(data.name){
      this.name = data.name
    }
    if(data.level){
      this.level = `${data.level}`
    }
    if(data.parents){
      this.parents = data.parents.map((id: any) => typeof(id) === 'string' ? parseInt(String(id)) : id)
    }else{
      this.parents = []
    }
  }
}


export interface ContactInfoProps {
  name?: string | undefined
  mail?: string | undefined
  tel?: string | undefined
}


export class AlertData {
  id?: undefined | number
  location: undefined | Location
  date?: undefined | Date
  comment: string = ''
  description: undefined | AlertDescriptionNode[]
  user?: undefined | ContactInfoProps
  animal_mortality: undefined | boolean
  health_symptoms: undefined | boolean
  pollution_source: undefined | boolean
  alertpicture_set: undefined | File[]
  saved: undefined | boolean
  access_key?: string 

  constructor(data?: AlertData | any | undefined) {
    if (data == null) {
      return
    }

    if (data.id) {
      this.id = data.id
    }
    this.location = new Location(data.location)
    if (data.date) {
      this.date = new Date(data.date)
    }
    if (data.comment) {
      this.comment = data.comment
    }
    if (data.description) {
      this.description = data.description
    }
    if (data.user) {
      this.user = data.user
    }
    if (data.animal_mortality) {
      this.animal_mortality = data.animal_mortality
    }
    if (data.health_symptoms) {
      this.health_symptoms = data.health_symptoms
    }
    if (data.pollution_source) {
      this.pollution_source = data.pollution_source
    }
    if (data.alertpicture_set) {
      this.alertpicture_set = data.alertpicture_set
    }
    if (data.saved) {
      this.saved = data.saved
    }
  }

  /**
   * Get a short human-readable summary of the Alert.
   * @returns The summarized description.
   */
  toShortStringDescription(): string {
    let tree: string = ''
    let date: string = ''

    if (this.description) {
      tree = this.description.reduce((prev, cur) => {return prev + '/' + cur.name}, '')
      tree = tree.slice(1)  // Discard first '/'
    } else {
      tree = '(alerte vide)'
    }

    if (this.date) {
      date = `(${this.date.toLocaleDateString()})`
    } else {
      date = '(date vide)'
    }
  
    return tree + ' ' + date
  }

  /**
   * Get a short human-readable summary of the Alert displayed in pretty HMTL component
   * @returns The summarized description.
   */
  toShortHtmlDescription(): JSX.Element {
    let tree: string = ''
    let date: string = ''

    if (this.description) {
      tree = this.description.reduce((prev, cur) => {return prev + '/' + cur.name}, '')
      tree = tree.slice(1)  // Discard first '/'
    } else {
      tree = '(alerte vide)'
    }
    
    if (this.date) {
      date = `(${this.date.toLocaleDateString()})`
    } else {
      date = '(date vide)'
    }

    return (
      <div>
        {tree}
        <br />
        <span className="em_light">{date}</span>
      </div>
    )
  }

}

/** Physical or chemical parameter */
export class Parameter {
  id: string | undefined
  name: string
  unit: string
  description: string
  constructor(data: any) {
    if (data.id) {
      this.id = String(data.id)
    }
    if (data.name) {
      this.name = data.name
    } else {
      this.name = ""
    }
    if (data.unit) {
      this.unit = data.unit
    } else {
      this.unit = ""
    }
    if (data.description) {
      this.description = data.description
    } else {
      this.description = ""
    }
  }
}


/** Measurement during an Analysis */
export class Measurement {
  parameter: Parameter | undefined
  par_name?: string
  par_unit?: string
  par_description?: string
  value: number | undefined
  date: Date | undefined

  constructor(data: any) {
    if (data.parameter) {
      this.parameter = data.parameter
    }
    if (data.par_name) {
      this.par_name = data.par_name
    }
    if (data.par_unit) {
      this.par_unit = data.par_unit
    }
    if (data.par_description) {
      this.par_description = data.par_description
    }
    if (data.value) {
      this.value = data.value
    }
    if (data.date) {
      if (data.date instanceof Date) {
        this.date = data.date
      } else {
        this.date = new Date(data.date)
      }
    }
  }

  get_name() {
    if (this.parameter) {
      return this.parameter.name
    }
    return this.par_name
  }

  get_unit() {
    if (this.parameter) {
      return this.parameter.unit
    }
    return this.par_unit
  }
}

/** Analysis at a given point during an intervention */
export interface AnalysisOld {
  name: string
  location: Location | undefined
  measurement_set: Array<Measurement | undefined>
}

/** Analysis at a given point during an intervention */
export class Analysis {
  name: string = ''
  location: Location = new Location(undefined)
  measurement_set: Measurement[] = []

  constructor(data: any) {
    if (data){
      if (data.name) {
        this.name = data.name
      }
      this.location = new Location(data.location)
      if (data.measurement_set) {
        this.measurement_set = []
        data.measurement_set.forEach((item: any) => {
          if (item) {
            this.measurement_set.push(new Measurement(item))
          }
        })
      }
    }
  }
}


/** Structure to store a file */
export class CustomDocument {
  id: number | undefined
  intervention_id: number | undefined
  webpath: string | undefined
  file: File | undefined
  delete: boolean
  name: string

  constructor(data?: any){
    this.delete = false
    this.name = ""
    if (data) {
      if (data.id) {
        this.id = data.id
      }
      if (data.intervention_id) {
        this.intervention_id = data.intervention_id
      } else if (data.intervention) {
        this.intervention_id = data.intervention.id
      }
      if (typeof data.file === "string" || data.file instanceof String) {
        this.webpath = data.file 
      } else if(data.file instanceof File) {
        this.file = data.file 
        this.name = data.file.name
      }
      if (data.webpath) {
        this.webpath = data.webpath 
      }
      if (data.delete === true) {
        this.delete = true
      }
      if (data.name) {
        this.name = data.name
      } else if (this.name ==="" && this.webpath!==undefined) {
        this.name = this.webpath.split('/').splice(-1)[0]
      }
      if (data.delete) {
        this.delete
      }
    }
  }
}

/** Structure of an intervention */
export class Intervention {
  id?: number
  name: string = ''
  user?: number
  username: string = ''
  /** The location of an intervention is the mean location (barycenter) of the associated analyses. This is automatically recomputed by the server. */
  location: Location = new Location(undefined)
  /** id of the alert to wich the intervention is an answer */
  alertId: number | undefined
  date: Date | undefined
  creationDate: Date | undefined
  comment: string = ''
  /** analyses at different points */
  analysis_set: Analysis[] = []
  /** supporting documents */
  document_set: CustomDocument[] = []
  saved?: boolean | undefined
  permissions?: {edit: boolean}

  constructor(data?: any) {
    if (data == null) {
      return
    }
    
    if (data) {
      if (data.id) {
        this.id = data.id
      }
      if (data.name) {
        this.name = data.name
      }
      if (data.username) {
        this.username = data.username
      }
      this.location = new Location(data.location)
      if (data.alertId) {
        this.alertId = data.alertId
      }
      if (data.date) {
        if (data.date instanceof Date) {
          this.date = data.date
        } else {
          this.date = new Date(data.date)
        }
      }
      if (data.creationDate) {
        if (data.date instanceof Date) {
          this.creationDate = data.creationDate
        } else {
          this.creationDate = new Date(data.creationDate)
        }
      }
      if (data.comment) {
        this.comment = data.comment
      }
      if (data.analysis_set) {
        this.analysis_set = data.analysis_set.map((item: any) => new Analysis(item))
      }
      if (data.document_set) {
        this.document_set = data.document_set.map((doc:any)=>new CustomDocument(doc))
      }
      if (data.saved) {
        this.saved = data.saved
      }
      this.permissions = {"edit": false}
      if (data.permissions){
        if (data.permissions.edit){
          this.permissions = {"edit": true}
        }
      }
    }
  }

  shortDescription(html: boolean = true) {
    let res: string = this.name ? this.name : '-Intervention-'
    res = res + (this.username ? ' par ' + this.username + ', ' : '')
    let res2: string = ''
    if (this.date instanceof Date) {
      res2 = `(${this.date.toLocaleDateString()})`
    } else if (typeof this.date === 'string') {
      res2 = `(${this.date})`
    }
    if (html) {
      return (
        <div>
          {res}
          {res2 ? (
            <>
              <br />
              <span className="em_light">{res2}</span>
            </>
          ) : (
            <></>
          )}
        </div>
      )
    }
    return `${res} ${res2}`
  }
}

/** describe an intervention */
export function interventionShortDescription(data: Intervention) {
  let res: string = data.name ? data.name : '-Intervention-'
  res = res + (data.username ? ' par ' + data.username + ', ' : '')
  res = res + (data.date ? ' le ' + data.date.toLocaleString() : '')
  if (data.date instanceof Date) {
    res = `${res} (${data.date.toDateString()})`
  } else if (typeof data.date === 'string') {
    res = `${res} (${data.date})`
  }
  return res
}

/** Filter specification to be sent to the server to get matching Alerts and/or Interventions */
export interface Filter {
  type: undefined | string
  from_date: undefined | Date
  to_date: undefined | Date
  keywords: undefined | string
  longitude: undefined | number
  latitude: undefined | number
  radius: undefined | number
  max_num: undefined | number
  excel?: boolean
}

/** Longitude and latitude */
export interface Lonlat {
  longitude: number | undefined
  latitude: number | undefined
}
